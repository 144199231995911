import { publicUrlFor } from "../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../globals/route-names";
import XIcon from "@mui/icons-material/X";

function Footer1() {
  return (
    <>
      <footer
        className="footer-dark"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "images/lacnar-img/footer-bg.jpg"
          )})`,
        }}
      >
        <div className="container">
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom">
            <div className="footer-top">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="widget widget_services ftr-list-center">
                        <h3 className="widget-title">Address</h3>
                        <ul>
                          <li>
                            <NavLink to={publicUser.pages.CONTACT}>
                              LACNAR SERVICES LLP 1715, E AND F BLOCK ,
                              DATTAGALLI , MYSORE KUVEMPUNAGAR MYSORE-570023
                              KARNATAKA
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.pages.CONTACT}>
                              Email : info@lacnar.com, contact@lacnar.com
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.pages.CONTACT}>
                              Call : +91 91104 24920
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="widget widget_services ftr-list-center">
                        <h3 className="widget-title">Helpful Resources</h3>
                        <ul>
                          <li>
                            <NavLink to={publicUser.pages.ABOUT}>About</NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.blog.LIST}>Blogs</NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.pages.FAQ}>FAQ</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="widget widget_services ftr-list-center">
                        <h3 className="widget-title">Quick Links</h3>
                        <ul>
                          <li>
                            <NavLink to={publicUser.policy.PRIVACY}>
                              Privacy Policy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.policy.REFUND}>
                              Refund Policy
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.policy.TERMS}>
                              Terms & Conditions
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* FOOTER COPYRIGHT */}
            <div className="footer-bottom">
              <div className="footer-bottom-info">
                <div className="footer-copy-right">
                  <span className="copyrights-text">
                    Copyright © 2024, by Lacnar All Rights Reserved | Designed &
                    Developed by &nbsp;
                    <a
                      href="https://c2m-edutech.com/"
                      className="footer-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      C2M Edutech Solutions Ltd
                    </a>
                  </span>
                </div>
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/share/3PbGzRyfyNjvNbL4/?mibextid=qi2Omg"
                      className="fab fa-facebook-f"
                      target="blank"
                    />
                  </li>
                  <li>
                    <a
                      href="https://x.com/LACNAR151933?t=Yb3p3eVYVHACrta9-LRMjA&s=08"
                      // className="fab fa-twitter"
                      target="blank"
                    >
                      <XIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/lacn.ar?igsh=NjU0Mmt2ZHRleWN6"
                      className="fab fa-instagram"
                      target="blank"
                    />
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/"
                      className="fab fa-youtube"
                      target="blank"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer1;
