import { Route, Routes } from "react-router-dom";
import { adprovider } from "../globals/route-names";

import AdDashboardPage from "../app/pannels/ad-provider/components/ad-dashboard";
import AdDealsPage from "../app/pannels/ad-provider/components/ad-deal";
import AdFaqPage from "../app/pannels/ad-provider/components/ad-faq";
import AdPaymentHistoryPage from "../app/pannels/ad-provider/components/ad-payments";
import AdManageAdPage from "../app/pannels/ad-provider/jobs/ad-manage-ad";
import AdPostAdPage from "../app/pannels/ad-provider/jobs/ad-post-ad";
import AdMessagesPage from "../app/pannels/ad-provider/messages/ad-messages1";
import AdNotificationPage from "../app/pannels/ad-provider/components/ad-notifications";
import AdProfilePage from "../app/pannels/ad-provider/components/ad-profile";
import AdPasswordPage from "../app/pannels/ad-provider/components/ad-update-password";

function AdProviderRoutes() {
  return (
    <Routes>
      <Route path={adprovider.DASHBOARD} element={<AdDashboardPage />} />
      <Route path={adprovider.DEAL} element={<AdDealsPage />} />
      <Route path={adprovider.FAQ} element={<AdFaqPage />} />
      <Route path={adprovider.PAYMENTS} element={<AdPaymentHistoryPage />} />
      <Route path={adprovider.MANAGE_AD} element={<AdManageAdPage />} />
      <Route path={adprovider.POST_A_AD} element={<AdPostAdPage />} />
      <Route path={adprovider.MESSAGES1} element={<AdMessagesPage />} />
      <Route path={adprovider.NOTIFICATIONS} element={<AdNotificationPage />} />
      <Route path={adprovider.PROFILE} element={<AdProfilePage />} />
      <Route path={adprovider.UPDATE_PASSWORD} element={<AdPasswordPage />} />
    </Routes>
  );
}

export default AdProviderRoutes;
