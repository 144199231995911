import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Divider,
  Modal,
  Box,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";
import { publicUser } from "../../../../globals/route-names";
import { useSpring, animated } from "react-spring";
import ReactConfetti from "react-confetti";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { employer, empRoute } from "../../../../globals/route-names";

const useStyles = makeStyles(() => ({
  border: {
    border: "1px solid #D6D6D6",
    padding: "1rem",
    borderRadius: "6px",
    marginTop: "1.3rem",
  },
  parent: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  background: {
    backgroundColor: "#F4F6F7",
    padding: "2rem",
    borderRadius: "6px",
  },
  text: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    alignItems: "center",
  },
  modalStyle: {
    position: "absolute",
    top: "25%",
    left: "45%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
    borderRadius: "10px",
    textAlign: "center",
  },
}));

const AnimatedBox = animated(Box);

function EmpCart(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const { value } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const top100Films = [
    { label: "1 month" },
    { label: "2 months" },
    { label: "3 months" },
  ];

  let defaultValue = top100Films[0]; // Default to "1 month"

  const numericValue = Number(value);

  if (numericValue === 4999) {
    defaultValue = top100Films[1]; // "2 months"
  } else if (numericValue === 9999) {
    defaultValue = top100Films[2]; // "3 months"
  }
  const gstRate = 18; // GST rate of 18%
  const gstAmount = (0 * gstRate) / 100;
  const totalPrice = 0 + gstAmount;

  useEffect(() => {
    dispatch(fetchGeneralempAds());
  }, [dispatch]);

  const handlePayClick = async () => {
    setLoading(true);
    const plan =
      numericValue === 0
        ? "Basic"
        : numericValue === 4999
        ? "Standard"
        : numericValue === 7999
        ? "Advanced"
        : numericValue === 9999
        ? "Premium"
        : "Elite";
    try {
      const response = await axios.get(
        `https://lacnar.com/php/inventory.php?uuid=${userUUID}&plan=${plan}`
      );
      if (response.status === 200) {
        setApiSuccess(true);
        setModalOpen(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("API call failed: ", error);
      setApiSuccess(false);
      setModalOpen(true);
      setLoading(false);
    }
  };

  const handleClose = () => {
    navigate(empRoute(employer.INVENTORY));
  };

  const animationProps = useSpring({
    opacity: modalOpen ? 1 : 0,
    transform: modalOpen ? "translateY(0)" : "translateY(-100%)",
  });

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} md={4}>
        <div className="wt-admin-right-page-header clearfix">
          <h2>Your Cart</h2>
          <div className="breadcrumbs">
            <a>Home</a>
            <a>Dashboard</a>
            <span>Your Cart</span>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={8}>
        <NavLink to={empAds?.Cart_Header_Link} target="_blank">
          <img
            src={empAds?.Cart_Header}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </Grid>

      <Grid item xs={12} sm={12} md={7}>
        <h5>lacnar is a trusted growth partner to millions of everyday</h5>
        <div className={classes.border}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} className={classes.parent}>
              <div>
                <h5>
                  {numericValue === 0
                    ? "Basic Subscription"
                    : numericValue === 4999
                    ? "Standard Subscription"
                    : numericValue === 7999
                    ? "Advanced Subscription"
                    : numericValue === 9999
                    ? "Premium Subscription"
                    : "Elite Subscription"}
                </h5>

                <div style={{ marginTop: "1rem" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    disabled
                    options={""}
                    sx={{ width: 250 }}
                    defaultValue={"1 month"} // Set default value here
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="1 month"
                        label="Months"
                      />
                    )}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <NavLink to={publicUser.policy.TERMS} target="blank">
                    <p style={{ color: "green" }}>
                      Please read terms and conditions <br /> before continue
                    </p>
                  </NavLink>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              align="end"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>
                <h5
                  style={{ color: "#09757A", textDecoration: "line-through" }}
                >
                  ₹{Number(value).toFixed(2)}
                </h5>
              </div>
              <div>
                <h5 style={{ color: "#09757A" }}>Free</h5>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={5} style={{ marginTop: "2.3rem" }}>
        <div className={classes.background}>
          <div style={{ marginBottom: "1rem" }}>
            <h3>Order Summary (1 item) </h3>
            <hr />

            <div
              style={{
                margin: "1rem 0rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>Product</h5>
              <h5 style={{ color: "#09757A" }}>
                {numericValue === 0
                  ? "Basic Subscription"
                  : numericValue === 4999
                  ? "Standard Subscription"
                  : numericValue === 7999
                  ? "Advanced Subscription"
                  : numericValue === 9999
                  ? "Premium Subscription"
                  : "Elite Subscription"}
              </h5>
            </div>
            <div
              style={{
                margin: "1rem 0rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>Price</h5>
              <h5 style={{ color: "#09757A" }}>₹{Number().toFixed(2)}</h5>
            </div>
            <div
              style={{
                margin: "1rem 0rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>GST (18%)</h5>
              <h5 style={{ color: "#09757A" }}>₹{gstAmount}</h5>
            </div>
          </div>
          <Divider />
          <div
            style={{
              margin: "1rem 0rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>Total (INR)</h4>
            <h4 style={{ color: "#09757A" }}>₹{totalPrice}</h4>
          </div>
          <div>
            <Button
              fullWidth
              variant="contained"
              style={{
                fontWeight: "bold",
                textTransform: "capitalize",
                fontSize: "16px",
                backgroundColor: "#14ca4f",
              }}
              onClick={handlePayClick}
            >
              {loading === true ? "Loading..." : "Redeem Free Subscription"}
            </Button>
          </div>
        </div>

        <div className={classes.text}>
          <h6>
            <CheckCircleOutlineIcon fontSize="small" /> Satisfaction Guaranteed
          </h6>
        </div>

        <div>
          <h6>
            If you're not completely happy with your purchase, contact our
            Guides, and we'll make it right.
          </h6>
        </div>
      </Grid>

      <Grid item xs={12}>
        <NavLink to={empAds?.Cart_Footer_Link} target="_blank">
          <img
            src={empAds?.Cart_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </Grid>

      <Modal
        open={modalOpen}
        // onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <AnimatedBox style={animationProps}>
          {apiSuccess && <ReactConfetti />}
          <div
            style={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              height: "90vh",
            }}
          >
            <div
              style={{
                padding: "1rem",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "400px",
                  backgroundColor: "whitesmoke",
                  padding: "1rem",
                  borderRadius: "12px",
                }}
              >
                <ThumbUpAltIcon
                  style={{ height: "50px", width: "50px", color: "#14ca4f" }}
                />
                <Typography id="modal-title" variant="h6" component="h2">
                  Inventory Added {apiSuccess ? "Successfully" : "Failed"}
                </Typography>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                  {apiSuccess
                    ? "Your Inventory is valid for 30 days, Please use it before that."
                    : "There was an issue with your payment. Please try again."}
                </Typography>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  sx={{ mt: 2 }}
                  style={{ backgroundColor: "#14ca4f" }}
                >
                  Check Inventory
                </Button>
              </div>
            </div>
          </div>
        </AnimatedBox>
      </Modal>
    </Grid>
  );
}

export default EmpCart;
