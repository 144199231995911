import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IconButton, Tooltip, Snackbar, Button } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { db } from "../../../../../config/fbConfig"; // Import your Firestore instance
import { doc, setDoc } from "firebase/firestore"; // Import Firestore methods

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  DOB: Yup.string().required("DOB is required"),
  fatherName: Yup.string().required("Father's Name is required"),
  gender: Yup.string().required("Gender is required"),
  file: Yup.mixed()
    .required("File is required")
    .test("fileSize", "File size must be less than 2MB", (value) => {
      return value && value.size <= MAX_FILE_SIZE;
    })
    .test("fileType", "Unsupported file type", (value) => {
      return (
        value &&
        (value.type === "application/pdf" ||
          value.type === "image/png" ||
          value.type === "image/jpeg")
      );
    }),
});

// Utility function to convert DD/MM/YYYY to YYYY-MM-DD
function convertDateFormat(dateString) {
  const [day, month, year] = dateString.split("/");
  return `${year}-${month}-${day}`;
}

function SectionCandidateBasicInfo({ profileData, userUUID }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [fileUrl, setFileUrl] = useState(""); // Added state for file URL

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const handleFormSubmit = async (values) => {
    const data = {
      Basic: {
        FirstName: values.firstName,
        LastName: values.lastName,
        DOB: values.DOB,
        FatherName: values.fatherName,
        Gender: values.gender,
        Aadhar: values.file.name, // Saving the file name, you might want to handle file upload differently
      },
    };

    try {
      await setDoc(doc(db, "Verify", userUUID), data, { merge: true });
      setMsg("Saved successfully");
      setOpen(true);
      setIsEditMode(false);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: profileData?.FirstName || "",
        lastName: profileData?.LastName || "",
        DOB: profileData?.DOB ? convertDateFormat(profileData.DOB) : "",
        fatherName: profileData?.FatherName || "",
        gender: profileData?.Gender || "",
        file: null,
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, errors, touched }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-tittle m-a0">Basic Information</h4>
            </div>
            <div className="edit-button-end">
              <Tooltip title={isEditMode ? "Close" : "Edit"}>
                <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                  {isEditMode ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <EditIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>First Name</label>
                    <Field
                      className="form-control"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Last Name</label>
                    <Field
                      className="form-control"
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>DOB</label>
                    <Field
                      className="form-control"
                      name="DOB"
                      type="date"
                      placeholder="25/09/1995"
                      disabled={!isEditMode}
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                    />
                    <ErrorMessage
                      name="DOB"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Father's Name</label>
                    <Field
                      className="form-control"
                      name="fatherName"
                      type="text"
                      placeholder="Father's Name"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="fatherName"
                      component="div"
                      className="error"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <Field
                      as="select"
                      name="gender"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    >
                      <option value="">Select...</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                      <option value="Others">Others</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="gender"
                      className="error-message"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Upload Aadhar</label>
                    <input
                      className="form-control"
                      name="file"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      type="file"
                      accept=".pdf, image/png, image/jpeg"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFieldValue("file", file);
                        if (file) {
                          const url = URL.createObjectURL(file);
                          setFileUrl(url); // Set the file URL
                        } else {
                          setFileUrl("");
                        }
                      }}
                      disabled={!isEditMode}
                    />
                    {errors.file && touched.file ? (
                      <div className="error" style={{ color: "red" }}>
                        {errors.file}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.open(fileUrl, "_blank")}
                      disabled={!fileUrl}
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                      }}
                    >
                      Preview Aadhar Document
                    </Button>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="text-left">
                    <Button
                      disabled={!isEditMode}
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                      }}
                      color="primary"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={handleClose}
            message={msg}
            action={
              <IconButton size="small" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Form>
      )}
    </Formik>
  );
}

export default SectionCandidateBasicInfo;
