
import React, { Component } from 'react';

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { loadScript } from '../../../../globals/constants';


function AdNotificationPage() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    const AdNotificationList = useSelector(state => state.AdNotification.List)
    return (
        <div> Employer Notifications</div>
    )
}
export default AdNotificationPage;