import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IconButton, Tooltip, Button, Snackbar } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { db, storage } from "../../../../../config/fbConfig";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Validation schema
const validationSchema = Yup.object().shape({
  resume: Yup.mixed()
    .required("Resume is required")
    .test(
      "fileSize",
      "File size is too large. Maximum size is 2MB.",
      (value) => !value || (value && value.size <= 2 * 1024 * 1024) // 2MB size limit
    ),
});

const SectionCandidateResume = ({ resumeURL, userUUID }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false, msg: "" });
  const [previewUrl, setPreviewUrl] = useState(resumeURL); // Initial preview URL

  const toggleEditMode = () => setIsEditMode((prev) => !prev);

  const handleCloseSnackbar = (event, reason) => {
    if (reason !== "clickaway") {
      setSnackbarState({ ...snackbarState, open: false });
    }
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      let uploadedResumeURL = resumeURL;
      if (values.resume) {
        const storageRef = ref(
          storage,
          `users/${userUUID}/resume-${Date.now()}`
        );
        await uploadBytes(storageRef, values.resume);
        uploadedResumeURL = await getDownloadURL(storageRef);
      }

      const data = { ResumeURL: uploadedResumeURL };

      const docRef = doc(db, "Verify", userUUID);
      await setDoc(docRef, data, { merge: true });

      setSnackbarState({ open: true, msg: "Resume uploaded successfully" });
      setIsEditMode(false);
      setPreviewUrl(uploadedResumeURL); // Set the preview URL to the uploaded file URL
    } catch (error) {
      console.error("Error uploading resume: ", error);
      setSnackbarState({ open: true, msg: "Failed to upload resume" });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        resume: null,
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-title m-a0">Resume Upload</h4>
            </div>
            <div className="edit-button-end">
              <Tooltip title={isEditMode ? "Close" : "Edit"}>
                <IconButton onClick={toggleEditMode}>
                  {isEditMode ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <EditIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="form-group">
                <label>Upload Resume</label>
                {isEditMode && (
                  <input
                    type="file"
                    name="resume"
                    style={{
                      backgroundColor:
                        isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                    }}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue("resume", file);

                      // Create a preview URL
                      if (file) {
                        const url = URL.createObjectURL(file);
                        setPreviewUrl(url);
                      }
                    }}
                    className="form-control"
                  />
                )}
                <ErrorMessage
                  name="resume"
                  component="div"
                  className="error-message"
                />
              </div>

              {previewUrl && (
                <div className="text-left mt-3">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor:
                        isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                    }}
                    onClick={() => window.open(previewUrl, "_blank")}
                  >
                    Preview Resume
                  </Button>
                </div>
              )}

              <div className="text-left mt-3">
                <Button
                  type="submit"
                  style={{
                    backgroundColor:
                      isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!isEditMode || isSubmitting}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
          <Snackbar
            open={snackbarState.open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={snackbarState.msg}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default SectionCandidateResume;
