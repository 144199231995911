function SectionProfile() {
  return (
    <>
      <h4 className="section-head-small mb-4">Information</h4>
      <div className="twm-s-info">
        <ul>
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-money-bill-wave" />
              <span className="twm-title">Company Name</span>
              <div className="twm-s-info-discription">LACNAR SERVICES LLP</div>
            </div>
          </li>
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-receipt gst-icon" />

              <span className="twm-title">GST</span>
              <div className="twm-s-info-discription">29AALFL3473F1Z1</div>
            </div>
          </li>
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-id-card" />
              <span className="twm-title">PAN</span>
              <div className="twm-s-info-discription">AALFL3473F</div>
            </div>
          </li>
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-mobile-alt" />
              <span className="twm-title">Phone</span>
              <div className="twm-s-info-discription">+91 91104 24920</div>
            </div>
          </li>
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-at" />
              <span className="twm-title">Email</span>
              <div className="twm-s-info-discription">info@lacnar.com</div>
            </div>
          </li>

          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-map-marker-alt" />
              <span className="twm-title">Address</span>
              <div className="twm-s-info-discription">
                LACNAR SERVICES LLP 1715, E AND F BLOCK , DATTAGALLI , MYSORE
                KUVEMPUNAGAR MYSORE-570023 KARNATAKA
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default SectionProfile;
