import { useState } from "react";
import { useNavigate } from "react-router-dom";
import JobZImage from "../../../../common/jobz-img";

function OtpInput({ otp, setOtp }) {
  return (
    <input
      name="otp"
      type="text"
      required
      className="form-control"
      placeholder="Enter OTP*"
      value={otp}
      onChange={(event) => setOtp(event.target.value)}
    />
  );
}

function OtpPage() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    navigate("/next-page"); // Replace with actual route
  };

  const handleResendOtp = () => {
    setResendDisabled(true);
    setTimeout(() => setResendDisabled(false), 30000); // 30 seconds cooldown
  };

  return (
    <div className="section-full site-bg-white" style={{ padding: 0 }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8 col-lg-6 col-md-5 twm-log-reg-media-wrap">
            <div className="twm-log-reg-media">
              <div className="twm-l-media">
                <JobZImage src="images/lacnar-img/login.png" alt="OTP Image" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-7"
            style={{ marginTop: "2rem" }}
          >
            <div className="twm-log-reg-form-wrap">
              <div className="twm-log-reg-inner">
                <div className="twm-log-reg-head">
                  <div className="twm-log-reg-logo">
                    <span className="log-reg-form-title">Enter OTP</span>
                  </div>
                </div>
                <form onSubmit={handleOtpSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <OtpInput otp={otp} setOtp={setOtp} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <button type="submit" className="site-button">
                          Verify OTP
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <button
                          type="button"
                          className="site-button"
                          onClick={handleResendOtp}
                          disabled={resendDisabled}
                        >
                          {resendDisabled ? "Resend OTP in 30s" : "Resend OTP"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpPage;
