import { createSlice } from "@reduxjs/toolkit";
import {
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpManageJob: [],
  loading: false,
  error: null,
};

//Action of Slice
const EmpManageJobSlice = createSlice({
  name: "EmpManageJob",
  initialState,
  reducers: {
    setEmpManageJobStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpManageJobSuccess: (state, action) => {
      state.loading = false;
      state.EmpManageJob = action.payload;
    },
    setEmpManageJobFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpManageJobStart,
  setEmpManageJobSuccess,
  setEmpManageJobFailure,
} = EmpManageJobSlice.actions;
export default EmpManageJobSlice.reducer;

export const fetchEmpManageJob = (userUUID) => (dispatch) => {
  try {
    dispatch(setEmpManageJobStart());

    const q = query(
      collection(firestore, "Jobs"),
      where("ClientId", "==", userUUID)
    );

    // Set up the onSnapshot listener
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const EmpManageJob = [];
        querySnapshot.forEach((doc) => {
          EmpManageJob.push(doc.data());
        });

        dispatch(setEmpManageJobSuccess(EmpManageJob));
      },
      (error) => {
        dispatch(setEmpManageJobFailure(error.message));
      }
    );

    // Optional: return the unsubscribe function to allow cleanup
    return unsubscribe;
  } catch (error) {
    dispatch(setEmpManageJobFailure(error.message));
  }
};
