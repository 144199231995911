function SectionFaqGeneral() {
  return (
    <>
      <div className="tw-faq-section">
        <div className="accordion tw-faq" id="sf-faq-accordion">
          {/*One*/}
          <div className="accordion-item">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              aria-expanded="true"
              data-bs-target="#FAQ1"
            >
              Where is my job posting advertised?
            </button>
            <div
              id="FAQ1"
              className="accordion-collapse collapse show"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                A digital interface the person causing the movement of goods
                uploads the relevant information prior to the commencement of
                movement of goods and generates e-way bill on the GST portal.
                Best service are provide us. simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.
              </div>
            </div>
          </div>
          {/*Two*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ2"
              aria-expanded="false"
            >
              What Makes Your Business Plans So Special?
            </button>
            <div
              id="FAQ2"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body"></div>
            </div>
          </div>
          {/*Three*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ3"
              aria-expanded="false"
            >
              Reset Password With Phone Number?
            </button>
            <div
              id="FAQ3"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                A digital interface the person causing the movement of goods
                uploads the relevant information prior to the commencement of
                movement of goods and generates e-way bill on the GST portal.
                Best service are provide us. simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.
              </div>
            </div>
          </div>
          {/*Four*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ4"
              aria-expanded="false"
            >
              How do I redeem a coupon?
            </button>
            <div
              id="FAQ4"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                A digital interface the person causing the movement of goods
                uploads the relevant information prior to the commencement of
                movement of goods and generates e-way bill on the GST portal.
                Best service are provide us. simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.
              </div>
            </div>
          </div>
          {/*Five*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ5"
              aria-expanded="false"
            >
              How long will it take to post my job?
            </button>
            <div
              id="FAQ5"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                A digital interface the person causing the movement of goods
                uploads the relevant information prior to the commencement of
                movement of goods and generates e-way bill on the GST portal.
                Best service are provide us. simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.
              </div>
            </div>
          </div>
          {/*Six*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ6"
              aria-expanded="false"
            >
              What is your cancellation policy?
            </button>
            <div
              id="FAQ6"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                A digital interface the person causing the movement of goods
                uploads the relevant information prior to the commencement of
                movement of goods and generates e-way bill on the GST portal.
                Best service are provide us. simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.
              </div>
            </div>
          </div>
          {/*Seven*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ7"
              aria-expanded="false"
            >
              Where Can I Find Market Research Reports?
            </button>
            <div
              id="FAQ7"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                A digital interface the person causing the movement of goods
                uploads the relevant information prior to the commencement of
                movement of goods and generates e-way bill on the GST portal.
                Best service are provide us. simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.
              </div>
            </div>
          </div>
          {/*Eight*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ8"
              aria-expanded="false"
            >
              Do I need to know PHP to use TheJobs?
            </button>
            <div
              id="FAQ8"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                A digital interface the person causing the movement of goods
                uploads the relevant information prior to the commencement of
                movement of goods and generates e-way bill on the GST portal.
                Best service are provide us. simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.
              </div>
            </div>
          </div>
          {/*Nine*/}
          <div className="accordion-item">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#FAQ9"
              aria-expanded="false"
            >
              How soon will I start receiving resumes?
            </button>
            <div
              id="FAQ9"
              className="accordion-collapse collapse"
              data-bs-parent="#sf-faq-accordion"
            >
              <div className="accordion-body">
                A digital interface the person causing the movement of goods
                uploads the relevant information prior to the commencement of
                movement of goods and generates e-way bill on the GST portal.
                Best service are provide us. simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFaqGeneral;
