import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function EmpResumeAlertsPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const resumeAlertList = useSelector((state) => state.EmpResumeAlert);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <>
      {resumeAlertList.loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Resume Alerts!</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Resume Alerts</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Resume_Header_Link} target="_blank">
                  <img
                    src={empAds?.Resume_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {resumeAlertList?.EmpResumeAlert.length > 0 ? (
            <div className="panel panel-default site-bg-white m-t30">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">
                  <i className="far fa-bell" />
                  Alerts
                </h4>
              </div>
              <div className="panel-body wt-panel-body">
                <div className="p-a20 table-responsive">
                  <table className="table twm-table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>Number Jobs posted</th>
                        <th>Title</th>
                        <th>Jobs Description</th>
                        <th>Times</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resumeAlertList?.EmpResumeAlert?.map((item) => (
                        <tr>
                          <td>{item.NumberOfJobs}</td>
                          <td>{item.Title}</td>
                          <td>{item.Description}</td>
                          <td>{item.Time}</td>
                          <td>
                            <button
                              title="View"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <i className="fa fa-eye" />
                            </button>
                            <button
                              title="Delete"
                              data-bs-toggle="modal"
                              data-bs-target="#logout-delete-profile"
                            >
                              <span className="far fa-trash-alt" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.Resume_Footer_Link} target="_blank">
              <img
                src={empAds?.Resume_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}
export default EmpResumeAlertsPage;
