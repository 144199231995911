import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Alert, Stack } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Index from "../common/no-data/Index";
import CircularProgress from "../../../common/loading/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchCanNotification } from "../../../../reducers/candidate/CanNotificationSlice";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";

function CanNotificationPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const ads = useSelector((state) => state.ads.ads);
  const CanNotificationList = useSelector(
    (state) => state.CanNotification.CanNotification
  );
  const [seenNotifications, setSeenNotifications] = useState(() => {
    const storedNotifications = localStorage.getItem("seenNotifications");
    return storedNotifications ? JSON.parse(storedNotifications) : [];
  });

  const handleNotificationClose = (index) => {
    const updatedNotifications = [...seenNotifications, index];
    setSeenNotifications(updatedNotifications);
    localStorage.setItem(
      "seenNotifications",
      JSON.stringify(updatedNotifications)
    );
  };

  const CanNotification = useSelector((state) => state.CanNotification);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchCanNotification(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralAds());
  }, [dispatch]);

  return (
    <>
      {CanNotification?.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
              <div className="wt-admin-right-page-header clearfix">
                <h2>Notifications</h2>
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <a href="#">Dasboard</a>
                  <span>Notifications</span>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
              <NavLink to={ads?.Msg_Header_Link} target="_blank">
                <img
                  src={ads?.Msg_Header}
                  alt="Banner Image"
                  className="img-fluid"
                />
              </NavLink>
            </div>
          </div>

          {CanNotification?.CanNotification?.length > 0 ? (
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              {CanNotificationList?.map((item, index) => {
                const isSeen = seenNotifications.includes(index);
                return (
                  <Grid key={index} item xs={12}>
                    <NavLink to={item.Link} target="_blank">
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert
                          icon={<NotificationsIcon fontSize="inherit" />}
                          severity={item.Read === true ? "success" : "info"}
                          action={
                            <Button
                              // onClick={() => handleNotificationClose(index)}
                              color="inherit"
                              size="small"
                            >
                              <Typography
                                style={{
                                  fontWeight: item.Read === true ? "0" : "550",
                                  fontSize: "14px",
                                  fontFamily: "Rubik",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.Author}
                              </Typography>
                            </Button>
                          }
                          sx={{
                            color: item.Read === true ? "grey" : "black",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: item.Read === true ? "0" : "550",
                              fontSize: "15px",
                              fontFamily: "Rubik",
                            }}
                          >
                            {item.Title}
                          </Typography>
                          <Typography
                            style={{ fontSize: "14px", fontFamily: "Rubik" }}
                          >
                            {item.Description}
                          </Typography>
                        </Alert>
                      </Stack>
                    </NavLink>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Index />
              </div>
            </>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={ads?.Msg_Footer_Link} target="_blank">
              <img
                src={ads?.Msg_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanNotificationPage;
