function RefundPolicy() {
  return (
    <>
      <div className="p-t120 p-b90 site-bg-white Policy">
        <div className="container">
          <h4>RETURN POLICY</h4>
          <ul>
            <p>
              Any queries/concerns related to deliveries should be addressed to
              the support team within 24 hrs. of the product received by the
              customer. Service can be returned within 15 days from the date of
              activation in case of wrong services. Prepaid orders are not
              eligible for refunds if returned. Payment refunds will be
              applicable only on prepaid orders in case incorrect services are
              delivered. We will not charge for replacements if wrong services
              are received by the customer. It is mandatory to send the photos
              of invoice & product to further the process in case a customer
              receives damaged products.
            </p>
          </ul>

          <h4>EXCHANGES:</h4>
          <ul>
            <p>
              Only size exchanges are allowed. Items can be exchanged for a
              similar size or a different size if the wrong product is
              delivered.
            </p>
          </ul>

          <h4>RETURN INTIMATION:</h4>
          <p>
            You can Email Us within 15 days from the date of activation. The
            details of the return (Order number and Reason for Return) should be
            shared with the Customer Support by email.
          </p>

          <h4>GUIDELINES FOR A VALID RETURN:</h4>
          <p>
            Products should be in original condition Box should be retained and
            returned All services sold on us are 100% genuine. We understand how
            much you value your every purchase. We are committed to making your
            shopping experience a delightful one.
          </p>

          <h4>CANCELLATION POLICY</h4>
          <p>
            An easy cancellation process is available for all our esteemed
            customers. You can cancel your order before the product has been
            shipped. Your entire order amount will be refunded in case of Online
            Payment
          </p>

          <h4>REFUND POLICY</h4>
          <p>
            Refunds are provided for PREPAID orders only. Your refund will be
            initiated within working days of receiving the refund request and
            authorization of the refund. Your refund is estimated to be credited
            in the account, used for order payment, between 45 days after
            initiating the refund. Please note that the timing of the actual
            account credit depends on multiple external agencies such as banks,
            payment gateways and external couriers.
          </p>

          <h4>MODE OF REFUND</h4>
          <p>
            For both cancelled orders & returned products (due to incomplete
            sets, incorrect style, wrong sizes, or defective products
            delivered), the refund amount will be credited back into the same
            account (online banking, credit card, debit card) that was used to
            make the purchase
          </p>
        </div>
      </div>
    </>
  );
}

export default RefundPolicy;
