function Loader() {
  return (
    <div className="loading-area">
      <div className="loading-box" />
      <div className="loading-pic">
        <div className="wrapper">
          <div className="cssload-loader" />
        </div>
      </div>
    </div>
  );
}

export default Loader;
