import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
  List: [
    {
      Id: "Primary-1",
    },
  ],
};

//Action of Slice
const EmpProfileSlice = createSlice({
  name: "EmpProfile",
  initialState,
  reducers: {},
});

//Export of Slice
export const {} = EmpProfileSlice.actions;
export default EmpProfileSlice.reducer;
