import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpDeals: [],
  loading: false,
  error: null,
};

//Action of Slice
const EmpDealsSlice = createSlice({
  name: "EmpDeals",
  initialState,
  reducers: {
    setEmpDealsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpDealsSuccess: (state, action) => {
      state.loading = false;
      state.EmpDeals = action.payload;
    },
    setEmpDealsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const { setEmpDealsStart, setEmpDealsSuccess, setEmpDealsFailure } =
  EmpDealsSlice.actions;
export default EmpDealsSlice.reducer;

export const fetchEmpDeals = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpDealsStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Public", "EmployerGeneral", "Deals")
    );
    const EmpDeals = [];
    querySnapshot.forEach((doc) => {
      EmpDeals.push(doc.data());
    });
    dispatch(setEmpDealsSuccess(EmpDeals));
  } catch (error) {
    dispatch(setEmpDealsFailure(error.message));
  }
};
