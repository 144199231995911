import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id : "Primary-1", 
            Title : "20% Off Medicines, Flat 20% Off on Medicine Orders above ₹1000",
            Author : "medplus",
            Date : "Jan 15, 2023",
            ImgUrl : "https://c2m-edutech.com/demo/deal_1.jpeg",
            CompanyWebsite : "https://www.medplusmart.com",
            OfferUrl : "https://www.medplusmart.com/promotions",
            Description : "The offer provides a 20% discount on the total cost of medicines.No coupon code required."
        },
        {
            Id : "Primary-2",
            Title : " Avail 50% off on vitamins & supplements on Orders above ₹5000",
            Author : "medplus",
            Date : "March 06, 2023",
            ImgUrl : "https://c2m-edutech.com/demo/deal_2.jpeg",
            CompanyWebsite : "https://www.medplusmart.com",
            OfferUrl : "https://www.medplusmart.com/promotions",
            Description : "Get upto 50% off on vitamins & supplements, No coupon code required."
        },
        {
            Id : "Primary-3",
            Title : "Get up to 8% off + extra 15% off on your first allopathy medicine",
            Author : "1mg",
            Date : "March 08, 2023",
            ImgUrl : "https://c2m-edutech.com/demo/deal_3.jpeg",
            CompanyWebsite : "https://www.medplusmart.com",
            OfferUrl : "https://www.medplusmart.com/promotions",
            Description : "Get up to 8% off + extra 15% coupon discoun on your first medicine order of ₹999 and above"
        }
    ]
}

//Action of Slice
const AdDealsSlice = createSlice({
    name: "CanDeals",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = AdDealsSlice.actions;
export default AdDealsSlice.reducer;