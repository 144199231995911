import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../globals/route-names";
import SectionPagination from "../../public-user/sections/common/section-pagination";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function AdDealsPage() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  const CanDealsList = useSelector((state) => state.CanDeals.List);

  return (
    <div>
      {/*Headline Display Start*/}
      <div className="wt-admin-right-page-header clearfix">
        <h2>Deal of the Day</h2>
        <div className="breadcrumbs">
          <a>Home ad</a>
          <a>Dasboard</a>
          <span>Deal of the Day</span>
        </div>
      </div>
      {/*Headline Display End*/}

      <div className="section-full p-t50 p-b90 site-bg-white">
        <div className="container">
          <div className="masonry-wrap row d-flex">
            {/*Block one*/}
            {CanDealsList.map((item) => (
              <div className="masonry-item col-lg-4 col-md-12">
                <div className="blog-post twm-blog-post-1-outer">
                  <div className="wt-post-media">
                    <NavLink to={item.CompanyWebsite} target="_blank">
                      <img src={item.ImgUrl} alt="Deal Img" />
                    </NavLink>
                  </div>
                  <div className="wt-post-info">
                    <div className="wt-post-meta ">
                      <ul>
                        <li className="post-date">{item.Date}</li>
                        <li className="post-author">
                          By{" "}
                          <NavLink to={publicUser.candidate.DETAIL1}>
                            {item.Author}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="wt-post-title ">
                      <h4 className="post-title">
                        <NavLink to={publicUser.blog.DETAIL}>
                          {item.Title}
                        </NavLink>
                      </h4>
                    </div>
                    d
                    <div className="wt-post-text ">
                      <p>{item.Description}</p>
                    </div>
                    <div className="wt-post-readmore ">
                      <NavLink
                        to={publicUser.blog.DETAIL}
                        className="site-button-link site-text-primary"
                      >
                        Know More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <SectionPagination />
        </div>
      </div>
    </div>
  );
}
export default AdDealsPage;
