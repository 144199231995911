import React, { useContext, useEffect, useState } from "react";
import { OnlineStatusContext } from "./OnlineStatusProvider";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const iconStyle = {
  fontSize: "4rem",
  color: "#f44336", // Red color for alerting
  marginBottom: "16px",
};

const OfflineNotification = () => {
  const isOnline = useContext(OnlineStatusContext);
  const [open, setOpen] = useState(!isOnline);

  useEffect(() => {
    setOpen(!isOnline);
  }, [isOnline]);

  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="offline-notification-title"
      aria-describedby="offline-notification-description"
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
        style: { backgroundColor: "rgba(0, 0, 0, 0.7)" },
      }}
    >
      <Box sx={modalStyle}>
        <SignalWifiOffIcon sx={iconStyle} />
        <Typography
          id="offline-notification-title"
          variant="h6"
          component="h2"
          gutterBottom
          color="textPrimary"
        >
          You are offline
        </Typography>
        <Typography
          id="offline-notification-description"
          variant="body2"
          color="textSecondary"
          align="center"
          paragraph
        >
          Please check your internet connection and try again.
        </Typography>
      </Box>
    </Modal>
  );
};

export default OfflineNotification;
