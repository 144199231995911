import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import JobZImage from "../../../common/jobz-img";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { canRoute, candidate, employer } from "../../../../globals/route-names";

function CanHeaderSection(props) {
  const [sortedData, setSortedData] = useState([]);
  const canMessages = useSelector((state) => state.CanDashboard.canMessages);
  const canNotifications = useSelector(
    (state) => state.CanDashboard.canNotifications
  );
  const profileData = useSelector((state) => state.CanDashboard.canProfile);

  const CanDashboard = useSelector((state) => state.CanDashboard);

  const formatTimeDifference = (firebaseTimestamp) => {
    const messageDate = new Date(firebaseTimestamp.seconds * 1000);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate - messageDate);

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
    }
  };

  useEffect(() => {
    if (canMessages?.MsgList) {
      const data = Object.entries(canMessages.MsgList).map(([key, value]) => ({
        Id: key,
        Name: value.Name,
        Date: value.TimeStamp, // Assuming TimeStamp is in a format directly convertible to a Date object
        Msg: value.Msg,
        TimeDifference: formatTimeDifference(value.TimeStamp),
      }));

      const sorted = data.sort((a, b) => {
        const dateA = new Date(a.Date); // Parsing Date property correctly
        const dateB = new Date(b.Date); // Parsing Date property correctly
        return dateB - dateA;
      });

      setSortedData(sorted);
    }
  }, [canMessages]);

  var Notification = [];
  if (canNotifications?.NotiList) {
    Object.entries(canNotifications.NotiList).forEach(([key, value]) => {
      Notification.push(
        Object.assign({
          Id: key,
          Msg: value.Msg,
          Priority: value.Priority,
        })
      );
    });
  }
  const NotificationData = Notification.sort((a, b) => a.Priority - b.Priority);

  return (
    <>
      <header id="header-admin-wrap" className="header-admin-fixed">
        <div id="header-admin" className={props.sidebarActive ? "" : "active"}>
          <div className="container">
            <div className="header-left">
              <div className="nav-btn-wrap">
                <a
                  className="nav-btn-admin"
                  id="sidebarCollapse"
                  onClick={props.onClick}
                >
                  <span className="fa fa-angle-left" />
                </a>
              </div>
            </div>
            <div className="header-right">
              <ul className="header-widget-wrap">
                {/*Notification*/}
                <li className="header-widget dashboard-noti-dropdown">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle jobzilla-admin-notification"
                      id="ID-NOTI_dropdown"
                      data-bs-toggle="dropdown"
                    >
                      <i className="far fa-bell" />
                      {canNotifications?.UnreadNoti > 0 && (
                        <span className="notification-animate">
                          {canNotifications?.UnreadNoti}
                        </span>
                      )}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="ID-NOTI_dropdown"
                    >
                      <div className="dashboard-widgets-header">
                        You have 7 notifications
                      </div>
                      <div className="noti-list dashboard-widget-scroll">
                        <ul>
                          {NotificationData?.map((item, index) => {
                            return (
                              <>
                                <li key={index}>
                                  <NavLink to={item.Link} target="_blank">
                                    <span className="noti-icon">
                                      <i className="far fa-bell" />
                                    </span>
                                    <span className="noti-texting">
                                      {item.Msg}
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <div className="noti-view-all">
                          <NavLink to={canRoute(candidate.NOTIFICATIONS)}>
                            View All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/*Account*/}
                <li className="header-widget">
                  <div className="dashboard-user-section">
                    <div className="listing-user">
                      <div className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          id="ID-ACCOUNT_dropdown"
                          data-bs-toggle="dropdown"
                        >
                          <div className="user-name text-black">
                            <span>
                              {profileData?.Basic?.ProfileURL !== undefined ? (
                                <img
                                  src={profileData?.Basic?.ProfileURL}
                                  alt=""
                                />
                              ) : (
                                <Avatar
                                  alt={profileData?.Basic?.FirstName}
                                  src="/static/images/avatar/1.jpg"
                                />
                              )}
                            </span>
                            {profileData?.Basic?.FirstName}
                          </div>
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="ID-ACCOUNT_dropdown"
                        >
                          <ul>
                            <li>
                              <NavLink to={canRoute(candidate.PROFILE)}>
                                <i className="fa fa-user" /> Profile
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={canRoute(candidate.UPDATE_PASSWORD)}>
                                <i className="fa fa-fingerprint" /> Update
                                Password
                              </NavLink>
                            </li>
                            <li>
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#logout-dash-profile"
                              >
                                <i className="fa fa-share-square" /> Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* Right Side Content End */}
          </div>
        </div>
        {/* Header End */}
      </header>
    </>
  );
}

export default CanHeaderSection;
