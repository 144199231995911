import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { ThemeProvider, createTheme, Pagination } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogs } from "../../../../../reducers/public-user/blogSlice";
import { publicUser } from "../../../../../globals/route-names";

function BlogListPage() {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blogList.blogs);

  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  // Transform and sort blogs before pagination
  const transformedBlogs = useMemo(() => {
    return blogs
      ?.map((item) => {
        if (item.Date && item.Date.seconds !== undefined) {
          const milliseconds = item.Date.seconds * 1000;
          const totalMilliseconds =
            milliseconds + item.Date.nanoseconds / 1000000;
          const date = new Date(totalMilliseconds);
          return {
            ...item,
            date: date,
            formattedDate: moment(date).format("MMM Do YYYY"),
          };
        }
        return item; // Handle cases where Date might be undefined
      })
      .sort((a, b) => b.date - a.date); // Sort the transformed blogs by date in descending order
  }, [blogs]);

  // Pagination
  const totalPages = Math.ceil(transformedBlogs?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(
    startIndex + itemsPerPage,
    transformedBlogs?.length
  );
  const currentItems = transformedBlogs.slice(startIndex, endIndex);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px", // Spacing between items
  };

  const itemStyle = {
    flex: "1 1 calc(50% - 20px)", // Adjust to fit 2 items per row
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  };

  const themes = createTheme({
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            padding: "22px",
            fontWeight: "400",
            fontFamily: "Rubik",
            fontSize: "16px",
            lineHeight: "50px",
            height: "50px",
            borderRadius: "12px",
            "&.Mui-selected": {
              backgroundColor: "#046434",
              color: "white",
              "&:hover": {
                backgroundColor: "#046434",
                color: "white",
              },
            },
          },
        },
      },
    },
  });

  return (
    <>
      <div className="section-full p-t120 p-b90 site-bg-white">
        <div className="container">
          <div style={containerStyle}>
            {currentItems?.map((item) => (
              <div style={itemStyle} key={item.Id}>
                <div className="blog-post twm-blog-post-1-outer twm-blog-list-style">
                  <div className="wt-post-media">
                    <img
                      src={item.ImgUrl}
                      alt=""
                      style={{ height: "13.4rem" }}
                    />
                  </div>
                  <div className="wt-post-info">
                    <div className="wt-post-meta">
                      <ul>
                        <li className="post-date">{item.formattedDate}</li>
                        <li className="post-author">
                          By{" "}
                          <NavLink to={publicUser.candidate.DETAIL1}>
                            {item.Author}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="wt-post-title">
                      <h4
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          lineHeight: "1.5", // Adjust based on your font size and line height
                        }}
                        className="post-title"
                      >
                        <NavLink to={`${publicUser.blog.DETAIL}/${item.Id}`}>
                          {item.Title}
                        </NavLink>
                      </h4>
                    </div>
                    <div style={{ width: "300px", overflow: "hidden" }}>
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          lineHeight: "1.5", // Adjust based on your font size and line height
                        }}
                      >
                        {item.Description}
                      </p>
                    </div>
                    <div className="wt-post-readmore">
                      <NavLink
                        to={`${publicUser.blog.DETAIL}/${item.Id}`}
                        className="site-button-link site-text-primary"
                      >
                        Read More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <ThemeProvider theme={themes}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              shape="rounded"
            />
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}

export default BlogListPage;
