import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection, query, where } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

const initialState = {
  CanSearchJob: [],
  loading: true,
  error: null,
};

const CanSearchJobsSlice = createSlice({
  name: "CanSearchJob",
  initialState,
  reducers: {
    setSearchJobsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setSearchJobsSuccess: (state, action) => {
      state.loading = false;
      state.CanSearchJob = action.payload;
    },
    setSearchJobsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setSearchJobsStart,
  setSearchJobsSuccess,
  setSearchJobsFailure,
} = CanSearchJobsSlice.actions;

export default CanSearchJobsSlice.reducer;

export const fetchSearchJobs =
  (designation, specialization, location) => async (dispatch) => {
    try {
      dispatch(setSearchJobsStart());
      const q = query(
        collection(firestore, "Jobs"),
        where("Designation", "==", designation),
        where("Specialization", "==", specialization),
        where("Disrtict", "==", location)
      );

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const CanSearchJobs = [];
          querySnapshot.forEach((doc) => {
            CanSearchJobs.push(doc.data());
          });

          dispatch(setSearchJobsSuccess(CanSearchJobs));
        },
        (error) => {
          dispatch(setSearchJobsFailure(error.message));
        }
      );

      return unsubscribe;
    } catch (error) {
      dispatch(setSearchJobsFailure(error.message));
    }
  };
