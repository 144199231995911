import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import JobZImage from "../../../common/jobz-img";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { empRoute, employer } from "../../../../globals/route-names";
import { fetchEmpNotification } from "../../../../reducers/employer/EmpNotificationSlice";

function EmpHeaderSection(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  const empNotifications = useSelector(
    (state) => state.EmpNotification.EmpNotification
  );

  const userDetails = useSelector((state) => state.AuthStatus.userDetails);

  const formatTimeDifference = (firebaseTimestamp) => {
    const messageDate = new Date(firebaseTimestamp.seconds * 1000);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate - messageDate);

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEmpNotification(userUUID));
  }, [dispatch]);

  return (
    <>
      <header id="header-admin-wrap" className="header-admin-fixed">
        {/* Header Start */}
        <div id="header-admin" className={props.sidebarActive ? "" : "active"}>
          <div className="container">
            <div className="header-left">
              <div className="nav-btn-wrap">
                <a
                  className="nav-btn-admin"
                  id="sidebarCollapse"
                  onClick={props.onClick}
                >
                  <span className="fa fa-angle-left" />
                </a>
              </div>
            </div>
            {/* Left Side Content End */}
            {/* Right Side Content */}
            <div className="header-right">
              <ul className="header-widget-wrap">
                <li className="header-widget dashboard-noti-dropdown">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle jobzilla-admin-notification"
                      id="ID-NOTI_dropdown"
                      data-bs-toggle="dropdown"
                    >
                      <i className="far fa-bell" />
                      {empNotifications?.UnreadNoti > 0 && (
                        <span className="notification-animate">
                          {empNotifications?.UnreadNoti}
                        </span>
                      )}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="ID-NOTI_dropdown"
                    >
                      <div className="dashboard-widgets-header">
                        You have {empNotifications?.length} notifications
                      </div>
                      <div className="noti-list dashboard-widget-scroll">
                        <ul>
                          {empNotifications?.map((item, index) => {
                            return (
                              <>
                                <li key={index}>
                                  <a href="#">
                                    <span className="noti-icon">
                                      <i className="far fa-bell" />
                                    </span>
                                    <span className="noti-texting">
                                      <NavLink
                                        to={empRoute(employer.NOTIFICATIONS)}
                                      >
                                        {item.Title}
                                      </NavLink>
                                    </span>
                                  </a>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <div className="noti-view-all">
                          <NavLink to={empRoute(employer.NOTIFICATIONS)}>
                            View All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/*Account*/}
                <li className="header-widget">
                  <div className="dashboard-user-section">
                    <div className="listing-user">
                      <div className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          id="ID-ACCOUNT_dropdown"
                          data-bs-toggle="dropdown"
                        >
                          <div className="user-name text-black">
                            {userDetails?.photoURL}
                            <span>
                              {userDetails?.photoURL !== null ? (
                                <img src={userDetails?.photoURL} alt="" />
                              ) : (
                                <Avatar>
                                  {userDetails?.displayName.charAt(0)}
                                </Avatar>
                              )}
                            </span>
                            {userDetails?.displayName}
                          </div>
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="ID-ACCOUNT_dropdown"
                        >
                          <ul>
                            <li>
                              <NavLink to={empRoute(employer.PROFILE)}>
                                <i className="fa fa-user" /> Profile
                              </NavLink>
                            </li>
                            <li>
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#logout-dash-profile"
                              >
                                <i className="fa fa-share-square" /> Logout
                              </a>
                            </li>
                            <hr />
                            <li>
                              <NavLink to={empRoute(employer.CONTACT)}>
                                <i className="fas fa-envelope" /> Contact
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* Right Side Content End */}
          </div>
        </div>
        {/* Header End */}
      </header>
    </>
  );
}

export default EmpHeaderSection;
