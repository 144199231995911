import React, { useState, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import {
  ThemeProvider,
  createTheme,
  Pagination,
  Stack,
  Tooltip,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import JobViewPopup from "../../../../common/popups/popup-job-view";
import { db } from "../../../../../config/fbConfig";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function SectionJobsList({ data, loading }) {
  console.log(loading, "loading//////////////////////////////");
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data?.length);
  const [selectedJob, setSelectedJob] = useState(null);
  const userRole = useSelector((state) => state.AuthStatus.userRole);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const currentItems = useMemo(
    () => data?.slice(startIndex, endIndex),
    [data, startIndex, endIndex]
  );

  const handleViewClick = (job) => {
    setSelectedJob(job);
  };

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const handleJobApply = async (data) => {
    if (!data) {
      navigate(publicUser.pages.LOGIN);
      return;
    }
    if (!selectedJob) {
      setMsg("Selected job is undefined");
      return;
    }
    try {
      const jobData = {
        ClientId: selectedJob.ClientId || "",
        Company: selectedJob.Company || "",
        Designation: selectedJob.Designation || "",
        District: selectedJob.District || "",
        Experience: selectedJob.Experience || "",
        JobDescription: selectedJob.JobDescription || "",
        JobTitle: selectedJob.JobTitle || "",
        JobType: selectedJob.JobType || "",
        Logo: selectedJob.Logo || "",
        Posts: selectedJob.Posts || "",
        Specialization: selectedJob.Specialization || "",
        State: selectedJob.State || "",
        Status: selectedJob.Status || "",
        id: selectedJob.id || "",
      };
      await setDoc(doc(db, "JobApplied", userUUID), jobData);
      setOpen(true);
      setMsg("Job Applied Successfully");
    } catch (error) {
      console.error("Error writing document: ", error);
      setMsg(`Error: ${error.message}`);
    }
  };

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiPaginationItem: {
            styleOverrides: {
              root: {
                padding: "22px",
                fontWeight: "400",
                fontFamily: "Rubik",
                fontSize: "16px",
                lineHeight: "50px",
                height: "50px",
                borderRadius: "12px",
                "&.Mui-selected": {
                  backgroundColor: "#046434",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#046434",
                    color: "white",
                  },
                },
              },
            },
          },
        },
      }),
    []
  );

  return (
    <div>
      {loading === true ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <div className="twm-jobs-list-wrap">
          {currentItems?.length > 0 ? (
            <ul>
              {currentItems.map((item, index) => (
                <li key={index}>
                  <div className="twm-jobs-list-style1 mb-5">
                    <div className="twm-media">
                      <img src={item.Logo} alt="#" />
                    </div>
                    <div className="twm-mid-content">
                      <NavLink
                        to={publicUser.jobs.DETAIL1}
                        className="twm-job-title"
                      >
                        <h4>{item.JobTitle}</h4>
                      </NavLink>
                      <p className="twm-job-address">
                        {item.District},&nbsp;{item.State}
                      </p>
                      <div>{item.Designation}</div>
                    </div>
                    <div className="twm-right-content">
                      <div className="twm-jobs-category green">
                        <span
                          className="twm-bg-green"
                          style={{
                            backgroundColor:
                              item.JobType === 0 ? "green" : "#48b7db",
                          }}
                        >
                          {item.JobType === 0 ? "Full-Time" : "Part-Time"}
                        </span>
                      </div>
                      <div style={{ marginTop: "1rem" }}>
                        <a
                          data-bs-toggle="modal"
                          href="#saved-jobs-view"
                          role="button"
                          className="custom-toltip"
                        >
                          <Tooltip title="View Job Details">
                            <IconButton onClick={() => handleViewClick(item)}>
                              <RemoveRedEyeIcon style={{ fontSize: "15px" }} />
                            </IconButton>
                          </Tooltip>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h4>No Data Found</h4>
            </div>
          )}
        </div>
      )}

      <JobViewPopup
        selectedJob={selectedJob}
        show={false}
        onApply={handleJobApply}
        userRole={userRole}
      />

      <ThemeProvider theme={theme}>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            shape="rounded"
          />
        </Stack>
      </ThemeProvider>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msg}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
}

export default SectionJobsList;
