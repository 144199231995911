import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";
import {
  Box,
  Typography,
  Button,
  Container,
  Tooltip,
  IconButton,
  Grid,
  Select,
  MenuItem,
  Snackbar,
  ThemeProvider,
  createTheme,
  Pagination,
  Stack,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  designationOptions,
  specializationOptions,
  locationOptions,
} from "../../../common/static-data";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import JobViewPopup from "../../../common/popups/popup-job-view";
import { db, firestore } from "../../../../config/fbConfig";
import { candidate, canRoute } from "../../../../globals/route-names";
import CircularProgress from "@mui/material/CircularProgress";

const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});

function CanSearchJobPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CanDashboard = useSelector((state) => state.CanDashboard.canDashboard);
  const ads = useSelector((state) => state.ads.ads);
  const loading = useSelector((state) => state.CanDashboard.loading);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [selectedJob, setSelectedJob] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedDesignation, setSelectedDesignation] =
    useState("all-designation");
  const [selectedSpecialization, setSelectedSpecialization] =
    useState("all-specialization");
  const [selectedLocation, setSelectedLocation] = useState("all-locations");
  const itemsPerPage = 6;
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data?.length);
  const currentItems = data.slice(startIndex, endIndex);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading) {
      dispatch(fetchDashboard(userUUID));
    }
  }, [dispatch, loading, userUUID]);

  useEffect(() => {
    if (loading) {
      dispatch(fetchGeneralAds());
    }
  }, [dispatch, loading]);

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleViewClick = (job) => {
    setSelectedJob(job);
  };

  const handleSearch = async (event) => {
    setSearchLoading(true);
    if (event) event.preventDefault();
    try {
      const filters = [];

      if (selectedDesignation !== "all-designation") {
        filters.push(where("Designation", "==", selectedDesignation));
      }
      if (selectedSpecialization !== "all-specialization") {
        filters.push(where("Specialization", "==", selectedSpecialization));
      }
      if (selectedLocation !== "all-locations") {
        filters.push(where("District", "==", selectedLocation));
      }

      let q;
      if (filters.length > 0) {
        q = query(collection(firestore, "Jobs"), ...filters);
      } else {
        q = query(collection(firestore, "Jobs"));
      }

      const querySnapshot = await getDocs(q);
      const jobs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(jobs);
      setSearchLoading(false);
    } catch (error) {
      setSearchLoading(false);
      console.error("Error getting documents: ", error);
    }
  };

  const handleSaveJobs = async (job) => {
    try {
      const jobData = {
        ClientId: job.ClientId || "",
        Company: job?.Company || "",
        CreatedAt: serverTimestamp(),
        Designation: job?.Designation || "",
        District: job?.District || "",
        Experience: job?.Experience || "",
        JobDescription: job?.JobDescription || "",
        JobTitle: job?.JobTitle || "",
        JobType: job?.JobType ?? "",
        Logo: job?.Logo || "",
        Posts: job?.Posts || "",
        Specialization: job?.Specialization || "",
        State: job?.State || "",
        Status: job?.Status || "",
        id: job?.id || "",
      };

      // Use job.id or a unique identifier for the document ID in the SavedJobs collection
      const docRef = doc(firestore, "Users", userUUID, "SavedJobs", job.id);
      await setDoc(docRef, jobData);
      setOpen(true);
      setMsg("Job Saved Successfully");
    } catch (error) {
      console.error("Error saving job: ", error);
      setMsg("Error: " + error.message);
    }
  };

  const handleJobApply = async () => {
    if (!selectedJob) {
      setMsg("Selected job is undefined");
      return;
    }
    try {
      const jobData = {
        ClientId: selectedJob?.ClientId || "",
        Company: selectedJob?.Company || "",
        CreatedAt: serverTimestamp(),
        Designation: selectedJob?.Designation || "",
        District: selectedJob?.District || "",
        Experience: selectedJob?.Experience || "",
        JobDescription: selectedJob?.JobDescription || "",
        JobTitle: selectedJob?.JobTitle || "",
        JobType: selectedJob?.JobType || "",
        Logo: selectedJob?.Logo || "",
        Posts: selectedJob?.Posts || "",
        Specialization: selectedJob?.Specialization || "",
        State: selectedJob?.State || "",
        Status: selectedJob?.Status || "",
        id: selectedJob?.id || "",
      };

      const docRef = doc(db, "JobApplied", userUUID);
      await setDoc(docRef, jobData);
      setOpen(true);
      setMsg("Job Applied Successfully");
    } catch (error) {
      console.error("Error writing document: ", error);
      setMsg(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Search Jobs</h2>
            <div className="breadcrumbs">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/dashboard">Dashboard</NavLink>
              <span>Search Jobs</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            {ads?.SearchJobs_Header_Link && (
              <NavLink to={ads.SearchJobs_Header_Link} target="_blank">
                <img
                  src={ads?.SearchJobs_Header}
                  alt="Banner Image"
                  className="img-fluid"
                />
              </NavLink>
            )}
          </div>
        </div>
      </div>

      <>
        {CanDashboard?.TopBar_1 !== "Profile : Verified" ? (
          <Container maxWidth="md">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "40vh",
                textAlign: "center",
                bgcolor: "background.default",
                p: 3,
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              {CanDashboard?.TopBar_1 === "Profile : Not Updated" ? (
                <UpdateIcon
                  style={{ color: "#39CE6D" }}
                  sx={{ fontSize: 60, mb: 2 }}
                />
              ) : (
                <VisibilityIcon
                  style={{ color: "#39CE6D" }}
                  sx={{ fontSize: 60, mb: 2 }}
                />
              )}

              <Typography variant="h5" gutterBottom>
                {CanDashboard?.TopBar_1 === "Profile : Not Updated"
                  ? "Complete Your Profile"
                  : "Your Profile is Under Verification"}
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                {CanDashboard?.TopBar_1 === "Profile : Not Updated"
                  ? "To enhance your job search experience, please update your profile with the latest Resume."
                  : "Your profile is under verification. Please wait for the process to complete."}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ backgroundColor: "#39CE6D" }}
                onClick={() => {
                  navigate(canRoute(candidate.PROFILE));
                }}
                sx={{ textTransform: "none", px: 4 }}
              >
                {CanDashboard?.TopBar_1 === "Profile : Not Updated"
                  ? "Update Profile"
                  : "View Profile"}
              </Button>
            </Box>
          </Container>
        ) : (
          <>
            <Box
              sx={{
                padding: 3,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Search for Jobs
              </Typography>
              <form onSubmit={handleSearch}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <Select
                      fullWidth
                      size="small"
                      value={selectedDesignation}
                      onChange={(e) => setSelectedDesignation(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Designation" }}
                    >
                      {designationOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Select
                      fullWidth
                      size="small"
                      value={selectedSpecialization}
                      onChange={(e) =>
                        setSelectedSpecialization(e.target.value)
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "Specialization" }}
                    >
                      {specializationOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Select
                      size="small"
                      fullWidth
                      value={selectedLocation}
                      onChange={(e) => setSelectedLocation(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Location" }}
                    >
                      {locationOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>

            {searchLoading === true ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <div className="twm-pro-view-chart-wrap">
                <div className="col-lg-12 col-md-12 mb-4">
                  <div className="panel panel-default site-bg-white m-t30">
                    <div className="panel-heading wt-panel-heading p-a20">
                      <h4 className="panel-title m-a0">All Jobs</h4>
                    </div>
                    <div className="panel-body wt-panel-body">
                      <div className="twm-D_table p-a20 table-responsive">
                        <table
                          id="jobs_applied_table"
                          className="table table-bordered twm-candidate-save-job-list-wrap"
                        >
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Job Title</th>
                              <th>Designation</th>
                              <th>Specialization</th>
                              <th>Location</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.length > 0 ? (
                              currentItems?.map((item, index) => (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="twm-candidate-save-job-list">
                                      <div className="twm-media">
                                        <div className="twm-media-pic">
                                          <img src={item.Logo} alt="#" />
                                        </div>
                                      </div>
                                      <div className="twm-mid-content">
                                        <h4>{item.JobTitle}</h4>
                                        <small>{item.Company}</small>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <a href="#">{item.Designation}</a>
                                  </td>
                                  <td>{item.Specialization}</td>
                                  <td>{item.District}</td>
                                  <td>
                                    <div className="twm-table-controls">
                                      <ul className="twm-DT-controls-icon list-unstyled d-flex justify-content-end">
                                        <li style={{ marginRight: "12px" }}>
                                          <a
                                            data-bs-toggle="modal"
                                            href="#saved-jobs-view"
                                            role="button"
                                            className="custom-toltip"
                                          >
                                            <Tooltip title="View Job">
                                              <IconButton
                                                onClick={() =>
                                                  handleViewClick(item)
                                                }
                                              >
                                                <RemoveRedEyeIcon
                                                  style={{ fontSize: "15px" }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </a>
                                        </li>
                                        <li>
                                          <Tooltip title="Save Job">
                                            <IconButton
                                              onClick={() =>
                                                handleSaveJobs(item)
                                              }
                                            >
                                              <BookmarkIcon
                                                style={{ fontSize: "15px" }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" style={{ textAlign: "center" }}>
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>Sl.No</th>
                              <th>Job Title</th>
                              <th>Designation</th>
                              <th>Specialization</th>
                              <th>Location</th>
                              <th>Action</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <ThemeProvider theme={themes}>
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  shape="rounded"
                />
              </Stack>
            </ThemeProvider>
          </>
        )}
      </>

      <JobViewPopup
        selectedJob={selectedJob}
        show={false}
        onApply={handleJobApply}
      />

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msg}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <div className="col-xl-12 col-lg-12 col-md-12 mb-3 mt-5">
        {ads?.SearchJobs_Footer_Link && (
          <NavLink to={ads.SearchJobs_Footer_Link} target="_blank">
            <img
              src={ads?.SearchJobs_Footer}
              alt="Banner Image"
              className="img-fluid"
            />
          </NavLink>
        )}
      </div>
    </div>
  );
}

export default CanSearchJobPage;
