import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { db } from "../../../../../config/fbConfig";
import { doc, setDoc } from "firebase/firestore";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";

import {
  designationOptions,
  specializationOptions,
  locationOptions,
} from "../../../../common/static-data";

const validationSchema = Yup.object({
  address_1: Yup.string().required("Address Line 1 is required"),
  address_2: Yup.string(),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  pincode: Yup.string().required("Pincode is required"),
});

const states = [{ label: "Karnataka", value: "Karnataka" }];

function SectionCandidateAddressInfo({ Address, userUUID }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ open: false, msg: "" });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const docRef = doc(db, "Verify", userUUID);
      await setDoc(docRef, { Address: values }, { merge: true });
      setSnackbar({ open: true, msg: "Saved successfully" });
      setIsEditMode(false);
    } catch (error) {
      console.error("Error writing document:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        address_1: Address?.Address_1 || "",
        address_2: Address?.Address_2 || "",
        country: Address?.Country || "",
        state: Address?.State || "",
        district: Address?.District || "",
        pincode: Address?.Pincode || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-title m-a0">Address Information</h4>
            </div>
            <div className="edit-button-end">
              <Tooltip title={isEditMode ? "Close" : "Edit"}>
                <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                  {isEditMode ? (
                    <CloseIcon fontSize="small" />
                  ) : (
                    <EditIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                {[
                  {
                    name: "address_1",
                    label: "Address Line 1",
                    placeholder: "Enter Address Line 1",
                    type: "text",
                  },
                  {
                    name: "address_2",
                    label: "Address Line 2",
                    placeholder: "Mysuru, Karnataka 570023",
                    type: "text",
                  },
                  { name: "state", label: "State", options: states },
                  {
                    name: "district",
                    label: "District",
                    options: locationOptions,
                  },
                  {
                    name: "pincode",
                    label: "Pincode",
                    placeholder: "75462",
                    type: "text",
                    pattern: "[0-9]*",
                  },
                ].map(({ name, label, options, ...rest }) => (
                  <div
                    key={name}
                    className={`col-xl-${options ? 4 : 12} col-lg-6 col-md-12`}
                  >
                    <div className="form-group city-outer-bx has-feedback">
                      <label>{label}</label>
                      <Field
                        as={options ? "select" : "input"}
                        name={name}
                        className="form-control"
                        disabled={!isEditMode}
                        style={{
                          backgroundColor:
                            isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                        }}
                        {...rest}
                      >
                        {options && (
                          <>
                            <option value="">Select {label}</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name={name}
                        className="error-message"
                        style={{ color: "red" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-left">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isEditMode || isSubmitting}
                  style={{
                    backgroundColor:
                      isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
          <Snackbar
            open={snackbar.open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={handleClose}
            message={snackbar.msg}
            action={
              <>
                <IconButton size="small" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
}

export default SectionCandidateAddressInfo;
