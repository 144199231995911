import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, TextField, Snackbar, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../../config/fbConfig";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";

const MyFormComponent = (props) => {
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [usersUUID, setUsersUUID] = useState("");

  useEffect(() => {
    setUsersUUID(uuidv4());
  }, []);

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      try {
        const docRef = doc(db, "Tickets", usersUUID);

        await setDoc(docRef, {
          Subject: values.title,
          Description: values.description,
          CreatedAt: serverTimestamp(),
          ClientId: userUUID,
          Status: true,
        });
        setOpen(true);
        setMsg("Ticket Generated successfully");

        // Delay closing the modal to ensure the Snackbar is visible for 3 seconds
        setTimeout(() => {
          props.handleCloses();
        }, 1500);
      } catch (error) {
        console.error("Error writing document: ", error);
      }
    },
  });

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-md-12">
        <div className="form-group">
          <TextField
            fullWidth
            label="Title"
            id="title"
            name="title"
            placeholder="Enter the title"
            variant="outlined"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            error={touched.title && Boolean(errors.title)}
            helperText={touched.title && errors.title}
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group">
          <TextField
            fullWidth
            label="Description"
            id="description"
            name="description"
            multiline
            rows={3}
            placeholder="Enter the Description"
            variant="outlined"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </div>
      </div>

      <div className="text-left m-t-20">
        <Button type="submit" variant="contained" color="primary">
          Add Ticket
        </Button>
      </div>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msg}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </form>
  );
};

export default MyFormComponent;
