import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id : "Primary-1",
        }
    ]
}

//Action of Slice
const AdDashboardSlice = createSlice({
    name: "AdDashboard",
    initialState,
    reducers: {} 
})

//Export of Slice
export const { } = AdDashboardSlice.actions;
export default AdDashboardSlice.reducer;