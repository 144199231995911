import { createSlice } from "@reduxjs/toolkit";
import {
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

// Initial Value of Slice
const initialState = {
  EmpTickets: [],
  loading: false,
  error: null,
};

// Action of Slice
const EmpTicketsSlice = createSlice({
  name: "EmpTickets",
  initialState,
  reducers: {
    setEmpTicketsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpTicketsSuccess: (state, action) => {
      state.loading = false;
      state.EmpTickets = action.payload;
    },
    setEmpTicketsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Export of Slice
export const {
  setEmpTicketsStart,
  setEmpTicketsSuccess,
  setEmpTicketsFailure,
} = EmpTicketsSlice.actions;
export default EmpTicketsSlice.reducer;

export const fetchEmpTickets = (userUUID) => (dispatch) => {
  try {
    dispatch(setEmpTicketsStart());

    const q = query(
      collection(firestore, "Tickets"),
      where("ClientId", "==", userUUID)
    );

    // Set up the onSnapshot listener
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const EmpTickets = [];
        querySnapshot.forEach((doc) => {
          EmpTickets.push(doc.data());
        });

        dispatch(setEmpTicketsSuccess(EmpTickets));
      },
      (error) => {
        dispatch(setEmpTicketsFailure(error.message));
      }
    );

    // Optional: return the unsubscribe function to allow cleanup
    return unsubscribe;
  } catch (error) {
    dispatch(setEmpTicketsFailure(error.message));
  }
};
