import SectionEmployerInfo from "../../sections/employers/detail/section-emp-info";
import SectionEmployersCandidateSidebar from "../../sections/common/section-emp-can-sidebar";
import SectionOfficeVideo1 from "../../sections/common/section-office-video1";
import SectionOfficePhotos3 from "../../sections/common/section-office-photos3";
import SectionAvailableJobsGrid from "../../sections/employers/detail/section-available-jobs-grid";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";

function AboutUsPage() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      <div className="section-full  p-t0 p-b90 bg-white">
        {/*Top Wide banner Start*/}
        {/* <SectionEmployerInfo /> */}
        {/*Top Wide banner End*/}
        <div className="container mt-5">
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 col-md-12 rightSidebar">
                <SectionEmployersCandidateSidebar type="2" />
              </div>
              <div className="col-lg-8 col-md-12">
                {/* Candidate detail START */}
                <div className="cabdidate-de-info">
                  <h4 className="twm-s-title m-t0">About Lacnar</h4>
                  <p>
                    In our country’s health sector, while Health Human
                    Resources, Health Professional Colleges, and Hospitals
                    generally align with WHO norms.There is a significant
                    disparity in their distribution. The southern regions have
                    better resources, while the northern regions face limited
                    availability. Healthcare facilities are predominantly
                    urban-centric, with health human resources following a
                    similar pattern. This has resulted in some hospitals being
                    overstaffed while others struggle to recruit adequate
                    manpower. In rural areas, the situation is particularly
                    dire.
                  </p>
                  <p>
                    Additionally, many students aspire to study and work abroad
                    but often struggle to find the right path and guidance for
                    their careers. Some are even misled by unethical agencies
                    with false promises. Recognizing these challenges, the
                    Lacnar team is committed to bridging the gap between Health
                    Professionals and Health Institutions by providing
                    innovative solutions. With a few simple steps, professionals
                    can upload the required documents, and health institutions
                    can efficiently recruit the necessary staff. Health
                    professionals can also choose institutions that best meet
                    their needs. Furthermore, hospitals and health institutions
                    can directly access a wide range of medical equipment and
                    devices from leading companies through the Lacnar platform,
                    enhancing their operational capabilities and economic
                    feasibility.
                  </p>

                  <h4 className="twm-s-title">LACNAR FEATURES</h4>
                  <ul className="description-list-2">
                    <li>
                      <i className="feather-check" />
                      Intuitive search and filter options: Easily find what
                      you're looking for based on your specific needs and
                      criteria.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Real-time updates and industry news: Stay informed about
                      the latest trends, developments, and opportunities in
                      healthcare.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Advanced analytics and reporting: Gain valuable insights
                      into user activity, market trends, and your own profile
                      performance.
                    </li>
                    <li>
                      <i className="feather-check" />
                      Join Lacnar today and experience the power of connection
                      in healthcare. It's time to bridge the gap and build a
                      healthier tomorrow, together.
                    </li>
                  </ul>
                  <div className="twm-two-part-section">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 m-b30">
                        <SectionOfficeVideo1 />
                      </div>
                    </div>
                  </div>
                  <SectionAvailableJobsGrid />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsPage;
