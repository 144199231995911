import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { db, storage } from "../../../../../config/fbConfig";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";

function SectionCandidateEducationInfo({ Qualifications, userUUID }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    ug: Qualifications?.UG || "",
    pg: Qualifications?.PG || "",
    phd: Qualifications?.PhD || "",
    specialization: Qualifications?.Specialization || "",
    others: Qualifications?.Others || "",
    ugFile: null,
    pgFile: null,
    phdFile: null,
    specializationFile: null,
    othersFile: null,
  });

  const [fileUrls, setFileUrls] = useState({
    ugFileUrl: Qualifications?.Documents?.ugFile || "",
    pgFileUrl: Qualifications?.Documents?.pgFile || "",
    phdFileUrl: Qualifications?.Documents?.phdFile || "",
    specializationFileUrl: Qualifications?.Documents?.specializationFile || "",
    othersFileUrl: Qualifications?.Documents?.othersFile || "",
  });

  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ open: false, msg: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      if (file.type !== "application/pdf") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Only PDF files are allowed",
        }));
        return;
      }

      if (file.size > 3 * 1024 * 1024) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size must be less than 3 MB",
        }));
        return;
      }

      setFormData((prevData) => ({ ...prevData, [name]: file }));
      const fileName = `${name}-${Date.now()}`;
      const storageRef = ref(storage, `users/${userUUID}/${fileName}`);
      try {
        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);
        setFileUrls((prevUrls) => ({ ...prevUrls, [`${name}Url`]: fileUrl }));
        setErrors((prevErrors) => {
          const { [name]: _, ...rest } = prevErrors;
          return rest;
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const validate = () => {
    const newErrors = {};
    if (formData.ug && !formData.ugFile)
      newErrors.ugFile = "UG file is required";
    if (formData.pg && !formData.pgFile)
      newErrors.pgFile = "PG file is required";
    if (formData.phd && !formData.phdFile)
      newErrors.phdFile = "PhD file is required";
    if (formData.specialization && !formData.specializationFile)
      newErrors.specializationFile = "Specialization file is required";
    if (formData.others && !formData.othersFile)
      newErrors.othersFile = "Others file is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const data = {
      Qualifications: {
        UG: formData.ug,
        PG: formData.pg,
        PhD: formData.phd,
        Specialization: formData.specialization,
        Others: formData.others,
        Documents: fileUrls,
      },
    };

    try {
      const docRef = doc(db, "Verify", userUUID);
      await setDoc(docRef, data, { merge: true });
      setSnackbar({ open: true, msg: "Saved successfully" });
      setIsEditMode(false);
      setFormData({
        ug: "",
        pg: "",
        phd: "",
        specialization: "",
        others: "",
        ugFile: null,
        pgFile: null,
        phdFile: null,
        specializationFile: null,
        othersFile: null,
      });
      setFileUrls({
        ugFileUrl: "",
        pgFileUrl: "",
        phdFileUrl: "",
        specializationFileUrl: "",
        othersFileUrl: "",
      });
      setErrors({});
    } catch (error) {
      console.error("Error writing document:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="panel panel-default">
        <div className="panel-heading wt-panel-heading p-a20">
          <h4 className="panel-title m-a0">Academic Information</h4>
        </div>
        <div className="edit-button-end">
          <Tooltip title={isEditMode ? "Close" : "Edit"}>
            <IconButton onClick={() => setIsEditMode(!isEditMode)}>
              {isEditMode ? (
                <CloseIcon fontSize="small" />
              ) : (
                <EditIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </div>
        <div className="panel-body wt-panel-body p-a20 m-b30">
          <div className="row">
            {[
              {
                name: "ug",
                label: "Under Graduate",
                placeholder: "Enter Under Graduate",
              },
              {
                name: "pg",
                label: "Post Graduate",
                placeholder: "Enter Post Graduate",
              },
              {
                name: "phd",
                label: "Ph.D",
                placeholder: "Ph.D For",
              },
              {
                name: "specialization",
                label: "Specialization",
                placeholder: "Enter Specialization",
              },
              {
                name: "others",
                label: "Others",
                placeholder: "Enter Others (if any)",
              },
            ].map(({ name, label, placeholder }) => (
              <div key={name} className="col-md-6">
                <div className="form-group">
                  <label>{label}</label>
                  <input
                    type="text"
                    name={name}
                    className="form-control"
                    value={formData[name]}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                    placeholder={placeholder}
                  />
                  {errors[name] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {errors[name]}
                    </div>
                  )}
                  {isEditMode && (
                    <div>
                      <label>Upload {label} Document</label>
                      <input
                        type="file"
                        name={`${name}File`}
                        className="form-control"
                        accept=".pdf"
                        onChange={handleFileChange}
                      />
                      {errors[`${name}File`] && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errors[`${name}File`]}
                        </div>
                      )}
                      {fileUrls[`${name}FileUrl`] && (
                        <div className="col-md-12 mt-3">
                          <div className="form-group">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                window.open(
                                  fileUrls[`${name}FileUrl`],
                                  "_blank"
                                )
                              }
                            >
                              Preview {label} Document
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="text-left">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isEditMode}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbar.msg}
        action={
          <>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </form>
  );
}

export default SectionCandidateEducationInfo;
