import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id : "Primary-1",
        }
    ]
}

//Action of Slice
const EmpPostJobSlice = createSlice({
    name: "EmpPostJob",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = EmpPostJobSlice.actions;
export default EmpPostJobSlice.reducer;