import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpCandidateSearch: [],
  loading: false,
  error: null,
};

//Action of Slice
const EmpCandidateSearchSlice = createSlice({
  name: "EmpCandidateSearch",
  initialState,
  reducers: {
    setEmpCandidateSearchStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpCandidateSearchSuccess: (state, action) => {
      state.loading = false;
      state.EmpCandidateSearch = action.payload;
    },
    setEmpCandidateSearchFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpCandidateSearchStart,
  setEmpCandidateSearchSuccess,
  setEmpCandidateSearchFailure,
} = EmpCandidateSearchSlice.actions;
export default EmpCandidateSearchSlice.reducer;

export const fetchEmpCandidateSearch =
  (designation, specialization, location) => async (dispatch) => {
    try {
      dispatch(setEmpCandidateSearchStart());
      const q = query(
        collection(firestore, "Candidates"),
        where("Designation", "==", designation),
        where("Specialization", "==", specialization),
        where("District", "==", location)
      );

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const EmpCandidateSearch = [];
          querySnapshot.forEach((doc) => {
            EmpCandidateSearch.push(doc.data());
          });

          dispatch(setEmpCandidateSearchSuccess(EmpCandidateSearch));
        },
        (error) => {
          dispatch(setEmpCandidateSearchFailure(error.message));
        }
      );

      return unsubscribe;
    } catch (error) {
      dispatch(setEmpCandidateSearchFailure(error.message));
    }
  };
