import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  CanResumeManager: [],
  loading: false,
  error: null,
};

//Action of Slice
const CanResumeManagerSlice = createSlice({
  name: "CanResumeManager",
  initialState,
  reducers: {
    setCanResumeManagerStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCanResumeManagerSuccess: (state, action) => {
      state.loading = false;
      state.CanResumeManager = action.payload;
    },
    setCanResumeManagerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setCanResumeManagerStart,
  setCanResumeManagerSuccess,
  setCanResumeManagerFailure,
} = CanResumeManagerSlice.actions;
export default CanResumeManagerSlice.reducer;

export const fetchCanResumeManager = (userUUID) => async (dispatch) => {
  try {
    dispatch(setCanResumeManagerStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "CanResumeManager")
    );
    const CanResumeManager = [];
    querySnapshot.forEach((doc) => {
      CanResumeManager.push(doc.data());
    });
    dispatch(setCanResumeManagerSuccess(CanResumeManager));
  } catch (error) {
    dispatch(setCanResumeManagerFailure(error.message));
  }
};
