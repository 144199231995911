import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import SectionCandicateBasicInfo from "../sections/profile/section-can-basic-info";
import SectionCandidateSocialInfo from "../sections/profile/section-can-social-info";
import SectionCandicateAddressInfo from "../sections/profile/section-can-address-info";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";
import SectionCandidateEducationInfo from "../sections/profile/section-can-education-info";
import SectionCandidateProfessionalInfo from "../sections/profile/section-can-professional-info";
import SectionCandidateResume from "../sections/profile/section-can-resume";
import SectionCandidateLookingInfo from "../sections/profile/section-can-looking-for";

function CanProfilePage() {
  const dispatch = useDispatch();
  const ads = useSelector((state) => state.ads.ads);
  const loading = useSelector((state) => state.CanDashboard.loading);
  const canProfile = useSelector((state) => state.CanDashboard.canProfile);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralAds());
  }, [dispatch]);

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        {" "}
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Profile</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Profile</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 mb-4">
            <NavLink to={ads?.Profile_Header_Link} target="_blank">
              <img
                src={ads?.Profile_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
          <div className="twm-right-section-panel site-bg-gray">
            {/*Basic Information*/}
            <SectionCandicateBasicInfo
              profileData={canProfile?.Basic}
              userUUID={userUUID}
            />

            {/*Social Network*/}
            <SectionCandidateEducationInfo
              socialNetwork={canProfile?.SocialNetwork}
              userUUID={userUUID}
            />

            <SectionCandidateResume
              profileData={canProfile?.Basic}
              userUUID={userUUID}
            />

            <SectionCandidateProfessionalInfo
              socialNetwork={canProfile?.SocialNetwork}
              userUUID={userUUID}
            />
            <SectionCandidateLookingInfo
              socialNetwork={canProfile?.SocialNetwork}
              userUUID={userUUID}
            />
            <SectionCandidateSocialInfo
              socialNetwork={canProfile?.SocialNetwork}
              userUUID={userUUID}
            />
            {/*Social Network*/}
            <SectionCandicateAddressInfo
              Address={canProfile?.Address}
              userUUID={userUUID}
            />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
            <NavLink to={ads?.Profile_Footer_Link} target="_blank">
              <img
                src={ads?.Profile_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default CanProfilePage;
