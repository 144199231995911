import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

// Define initial state
const initialState = {
  blogs: [],
  loading: false,
  error: null,
};

const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setBlogsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setBlogsSuccess: (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
    },
    setBlogsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setBlogsStart, setBlogsSuccess, setBlogsFailure } =
  blogSlice.actions;

export default blogSlice.reducer;

export const fetchBlogs = () => async (dispatch) => {
  try {
    dispatch(setBlogsStart());
    const docRef = doc(firestore, "Public", "Blog");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const blogsData = docSnap.data();
      var Data = [];
      if (blogsData) {
        Object.entries(blogsData).forEach(([key, value]) => {
          Data.push(
            Object.assign({
              Id: key,
              Author: value.Author,
              Date: value.Date,
              ImgUrl: value.ImgUrl,
              Title: value.Title,
              Description: value.Description,
              Video: value.Video,
              Description1: value.Description1,
            })
          );
        });
      }
      dispatch(setBlogsSuccess(Data));
    } else {
      dispatch(setBlogsFailure("Document not found"));
    }
  } catch (error) {
    dispatch(setBlogsFailure(error.message));
  }
};
