import React, { useState } from "react";
import { loadScript } from "../../../../globals/constants";
import { useSelector } from "react-redux";

function AdPaymentHistoryPage() {
    // Load custom script on component mount
    useState(() => {
        loadScript("js/custom.js");
    });

    // Fetch payment history data from Redux store
    const paymentHistoryList = useSelector(state => state.AdPaymentHistory.List);

    return (
        <>
            <div>
                {/* Headline Display Start */}
                <div className="wt-admin-right-page-header clearfix">
                    <h2>Payment History</h2>
                    <div className="breadcrumbs">
                        <a>Home</a>
                        <a>Dashboard</a>
                        <span>Payment History</span>
                    </div>
                </div>
                {/* Headline Display End */}

                <div className="twm-pro-view-chart-wrap">
                    <div className="col-lg-12 col-md-12 mb-4">
                        <div className="panel panel-default site-bg-white m-t30">
                            <div className="panel-heading wt-panel-heading p-a20">
                                <h4 className="panel-tittle m-a0">
                                    <i className="fas fa-money-bill" /> Payment History
                                </h4>
                            </div>
                            <div className="panel-body wt-panel-body">
                                <div className="twm-D_table p-a20 table-responsive">
                                    <table id="payment_history_table" className="table table-bordered twm-payment-history-list-wrap">
                                        <thead>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentHistoryList.map((item) => (
                                                <tr key={item.TransactionID}>
                                                    <td>{item.TransactionID}</td>
                                                    <td>{item.Name}</td>
                                                    <td>{item.Amount}</td>
                                                    <td>{item.Date}</td>
                                                    <td>
                                                        <span className={item.Status === 'Success' ? 'text-clr-green2' : 'text-clr-red'}>
                                                            {item.Status}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="twm-table-controls">
                                                            <ul className="twm-DT-controls-icon list-unstyled">
                                                                <li>
                                                                    <button title="View details" data-bs-toggle="tooltip" data-bs-placement="top">
                                                                        <span className="fa fa-eye" />
                                                                    </button>
                                                                </li>
                                                                {/* Add more action buttons as needed */}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdPaymentHistoryPage;
