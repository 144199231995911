import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

// Define initial state
const initialState = {
  featuredJobs: [],
  loading: false,
  error: null,
};

const featuredJobsSlice = createSlice({
  name: "featuredJobs",
  initialState,
  reducers: {
    setFeaturedJobsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setFeaturedJobsSuccess: (state, action) => {
      state.loading = false;
      state.featuredJobs = action.payload;
    },
    setFeaturedJobsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setFeaturedJobsStart,
  setFeaturedJobsSuccess,
  setFeaturedJobsFailure,
} = featuredJobsSlice.actions;

export default featuredJobsSlice.reducer;

export const fetchFeaturedJobs = () => async (dispatch) => {
  try {
    dispatch(setFeaturedJobsStart());
    const docRef = doc(firestore, "Public", "FeatureJobs");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const featuredJobs = docSnap.data();

      var Data = [];
      if (featuredJobs) {
        Object.entries(featuredJobs).forEach(([key, value]) => {
          Data.push(
            Object.assign({
              id: key,
              ClientId: value.ClientId,
              Company: value.Company,
              Date: value.Date,
              Designation: value.Designation,
              District: value.District,
              Experience: value.Experience,
              JobDescription: value.JobDescription,
              JobTitle: value.JobTitle,
              JobType: value.JobType,
              Logo: value.Logo,
              Posts: value.Posts,
              Page: value.Page,
              Specialization: value.Specialization,
              State: value.State,
              Status: value.Status,
              Priority: value.Priority,
            })
          );
        });
      }
      dispatch(setFeaturedJobsSuccess(Data));
    } else {
      dispatch(setFeaturedJobsFailure("Document not found"));
    }
  } catch (error) {
    dispatch(setFeaturedJobsFailure(error.message));
  }
};
