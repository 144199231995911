import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id : "Primary-1",
        }
    ]
}

//Action of Slice
const CanProfileSlice = createSlice({
    name: "CanProfile",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = CanProfileSlice.actions;
export default CanProfileSlice.reducer;