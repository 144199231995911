import { NavLink, useNavigate } from "react-router-dom";
import JobZImage from "../../../../common/jobz-img";
import { publicUser } from "../../../../../globals/route-names";
import { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "@mui/material";

function SignUpPage() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [resendInProgress, setResendInProgress] = useState(false);
  const [userData, setUserData] = useState(null);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    number: Yup.string()
      .matches(
        /^\d{10}$/,
        "Phone Number must be exactly 10 digits and contain no special characters"
      )
      .required("Phone Number is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    role: Yup.string().required("Role is required"),
  });

  const otpValidationSchema = Yup.object().shape({
    Phoneotp: Yup.string().required("Phone OTP is required"),
    Emailotp: Yup.string().required("Email OTP is required"),
  });

  const handleCandidateSignUp = async (values) => {
    try {
      const res = await axios.post(
        `https://lacnar.com/php/trigger_otp.php?phone=${values.number}&email=${values.email}`
      );
      if (res) {
        setOpen(true);
        setMsg("OTP Sent Successfully");
        setOtpSent(true);
        setUserData(values); // Store user data for later use
        setResendTimer(45); // Set the cooldown timer
      }
    } catch (error) {
      setOpen(true);
      setMsg("Error sending OTP");
      console.log(error);
    }
  };

  const handleOtpSubmit = async (values) => {
    if (!userData) return; // Ensure userData is available

    const { username, email, number, password, role } = userData;
    const { Phoneotp, Emailotp } = values;

    try {
      const response = await axios.post(
        `https://lacnar.com/php/create_user.php?email=${email}&password=${password}&phone=${number}&name=${username}&phoneOTP=${Phoneotp}&emailOTP=${Emailotp}&role=${role}`
      );

      if (response.status === 200) {
        setOpen(true);
        setMsg("Account Created Successfully");
        setTimeout(() => {
          navigate(publicUser.pages.LOGIN);
        }, 1500);
      } else {
        setMsg("Error creating user");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setMsg("Error creating user.", error);
    }
  };

  const handleResendOtp = async () => {
    if (!userData || resendTimer > 0) return; // Ensure userData is available and cooldown has passed

    setResendInProgress(true);

    try {
      const res = await axios.post(
        `https://lacnar.com/php/trigger_otp.php?phone=${userData.number}&email=${userData.email}`
      );
      if (res) {
        setOpen(true);
        setMsg("OTP Sent Successfully");
        setOtpSent(true);
        setResendTimer(45); // Set the cooldown timer
      }
    } catch (error) {
      setOpen(true);
      setMsg(res.message);
      console.log(error);
    } finally {
      setResendInProgress(false);
    }
  };

  useEffect(() => {
    let timer;

    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [resendTimer]);

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  return (
    <div className="section-full site-bg-white p-t100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-7 col-lg-7  col-md-7  twm-log-reg-media-wrap">
            <div className="twm-log-reg-media">
              <div className="twm-l-media">
                <JobZImage src="images/lacnar-img/signup.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5">
            <div className="twm-log-reg-form-wrap">
              <div className="twm-log-reg-inner">
                <div className="twm-log-reg-head">
                  <div className="twm-log-reg-logo">
                    <span className="log-reg-form-title">
                      {" "}
                      {!otpSent ? "Sign Up" : "Enter OTP"}
                    </span>
                  </div>
                </div>
                <div className="twm-tabs-style-2">
                  <div className="tab-content" id="myTab2Content">
                    {!otpSent ? (
                      <Formik
                        initialValues={{
                          username: "",
                          email: "",
                          number: "",
                          password: "",
                          confirmPassword: "",
                          role: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleCandidateSignUp}
                      >
                        {({ handleSubmit }) => (
                          <Form
                            className="tab-pane fade show active"
                            id="twm-signup-candidate"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group mb-3">
                                  <Field
                                    name="username"
                                    type="text"
                                    className="form-control"
                                    placeholder="Username*"
                                  />
                                  <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group mb-3">
                                  <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email*"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group mb-3">
                                  <Field
                                    name="number"
                                    type="number"
                                    className="form-control"
                                    placeholder="Phone Number*"
                                  />
                                  <ErrorMessage
                                    name="number"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group mb-3">
                                  <Field
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Password*"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group mb-3">
                                  <Field
                                    name="confirmPassword"
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm Password*"
                                  />
                                  <ErrorMessage
                                    name="confirmPassword"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group mb-3">
                                  <Field
                                    name="role"
                                    as="select"
                                    className="form-control"
                                  >
                                    <option value="" disabled>
                                      Select Role*
                                    </option>
                                    <option value="2">Candidate</option>
                                    <option value="1">Employer</option>
                                  </Field>
                                  <ErrorMessage
                                    name="role"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <button type="submit" className="site-button">
                                    Send OTP
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <Formik
                        initialValues={{ Phoneotp: "", Emailotp: "" }}
                        validationSchema={otpValidationSchema}
                        onSubmit={handleOtpSubmit}
                      >
                        {({ handleSubmit }) => (
                          <Form
                            className="tab-pane fade show active"
                            id="twm-signup-otp"
                            onSubmit={handleSubmit}
                          >
                            <div className="col-lg-12">
                              <div className="form-group mb-3">
                                <Field
                                  name="Emailotp"
                                  className="form-control"
                                  placeholder="Email OTP"
                                />
                                <ErrorMessage
                                  name="Emailotp"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-group mb-3">
                                <Field
                                  name="Phoneotp"
                                  className="form-control"
                                  placeholder="Phone OTP"
                                />
                                <ErrorMessage
                                  name="Phoneotp"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            {error && <p style={{ color: "red" }}>{error}</p>}
                            {success && (
                              <p style={{ color: "green" }}>{success}</p>
                            )}
                            <div className="col-md-12">
                              <div className="form-group">
                                <button type="submit" className="site-button">
                                  Verify OTP
                                </button>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <Button
                                  variant="text"
                                  className="site-button"
                                  onClick={handleResendOtp}
                                  disabled={resendInProgress || resendTimer > 0}
                                >
                                  <Typography style={{ color: "green" }}>
                                    {resendTimer > 0
                                      ? `Resend OTP (${resendTimer}s)`
                                      : resendInProgress
                                      ? "Resending..."
                                      : "Resend OTP"}
                                  </Typography>
                                </Button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msg}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
}

export default SignUpPage;
