import { NavLink } from "react-router-dom";

function JobViewPopup(props) {
  const { selectedJob, show, onApply, userRole } = props;

  const handleApplyButton = (data) => {
    if (onApply) {
      onApply(data); // Call the parent callback function
    }
  };

  return (
    <>
      <div
        className="modal fade twm-saved-jobs-view"
        id="saved-jobs-view"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel-3"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h2 className="modal-title" id="sign_up_popupLabel-3">
                  Company Details
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="modal-body-content">
                  <div
                    className="modal-item full-width description"
                    style={{ display: "flex" }}
                  >
                    <strong>Company Name </strong> &nbsp; : &nbsp;
                    <p>{selectedJob?.Company}</p>
                  </div>

                  <div
                    className="modal-item full-width description"
                    style={{ display: "flex" }}
                  >
                    <strong>Job Title </strong> &nbsp; : &nbsp;
                    <p>{selectedJob?.JobTitle}</p>
                  </div>

                  <div className="modal-item">
                    <strong>Experience </strong>
                    <p>{selectedJob?.Experience}</p>
                  </div>

                  <div className="modal-item">
                    <strong>Posts </strong>
                    <p>{selectedJob?.Posts}</p>
                  </div>
                  <div className="modal-item">
                    <strong>Job-Type </strong>
                    <p>
                      {selectedJob?.JobType === 1 ? "Part-Time" : "Full-Time"}
                    </p>
                  </div>
                  <div className="modal-item">
                    <strong>Designation </strong>
                    <p>{selectedJob?.Designation}</p>
                  </div>
                  <div className="modal-item">
                    <strong>Specialization </strong>
                    <p>{selectedJob?.Specialization}</p>
                  </div>
                  <div className="modal-item">
                    <strong>Location </strong>
                    <p>
                      {selectedJob?.District}, {selectedJob?.State}
                    </p>
                  </div>

                  <div className="modal-item full-width description">
                    <strong>Job Description </strong>
                    <p>{selectedJob?.JobDescription}</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {show !== true ? (
                  userRole !== 1 ? (
                    <button
                      type="button"
                      className="site-button"
                      data-bs-dismiss="modal"
                      onClick={() => handleApplyButton(userRole)}
                    >
                      {userRole === null ? "Login To Apply" : "Apply"}
                    </button>
                  ) : null
                ) : null}

                <button
                  type="button"
                  className="site-button pinte-clr"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Add CSS styles */}
      <style>
        {`
          .modal-body-content {
            display: grid;
            grid-template-columns: 1fr 1fr; /* Two columns layout */
            gap: 5px; /* Adjust spacing between items */
          }
          .modal-item.full-width {
            grid-column: span 2; /* Span across both columns */
          }
          .description p {
            // white-space: nowrap; /* Prevent text wrapping */
            // overflow: hidden;
            // text-overflow: ellipsis; /* Add ellipsis if text overflows */
          }
          .logo-image {
            width: 100px; /* Adjust logo size */
            height: auto;
          }
        `}
      </style>
    </>
  );
}

export default JobViewPopup;
