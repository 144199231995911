import JobZImage from "../../../common/jobz-img";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";
function AdManageAdPage() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    const manageAdList = useSelector(state => state.AdManageAd.List)
    return (
        <>
            <div className="wt-admin-right-page-header clearfix">
                <h2>Manage Ads</h2>
                <div className="breadcrumbs"><a>Home</a><a>Dasboard</a><span>My Ads Listing</span></div>
            </div>
            {/*Basic Information*/}
            <div className="panel panel-default">
                <div className="panel-heading wt-panel-heading p-a20">
                    <h4 className="panel-tittle m-a0"><i className="fa fa-suitcase" /> Ad Details</h4>
                </div>
                <div className="panel-body wt-panel-body p-a20 m-b30 ">
                    <div className="twm-D_table p-a20 table-responsive">
                        <table id="jobs_bookmark_table" className="table table-bordered twm-bookmark-list-wrap">
                            <thead>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Posted Ads</th>
                                    <th>Category</th>
                                    <th>Ad Types</th>
                                    <th>Applications</th>
                                    <th>Created &amp; Expired</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                manageAdList.map((item, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                    <td>
                                        <div className="twm-bookmark-list">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                <img src={item.ImgUrl} alt="" />
                                                </div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <a href="#" className="twm-job-title">
                                                    <h4>{item.Title}</h4>
                                                    <p className="twm-bookmark-address">
                                                        <i className="feather-map-pin" />{item.Address}
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{item.Category}</td>
                                    <td><div className="twm-jobs-category"><span className="twm-bg-brown">{item.JobType}</span></div></td>
                                    <td><a href="#" className="site-text-primary">{item.Applicants}</a></td>
                                    <td>
                                        <div>{item.CreatedDate}</div>
                                        <div>{item.ExpiredDate}</div>
                                    </td>
                                    <td>
                                        <div className="twm-table-controls">
                                            <ul className="twm-DT-controls-icon list-unstyled">
                                                <li>
                                                    <button title="View profile" data-bs-toggle="tooltip" data-bs-placement="top">
                                                        <span className="fa fa-eye" />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button title="Edit" data-bs-toggle="tooltip" data-bs-placement="top">
                                                        <span className="far fa-edit" />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button title="Delete" data-bs-toggle="tooltip" data-bs-placement="top">
                                                        <span className="far fa-trash-alt" />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                ))
                            }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Posted Jobs</th>
                                    <th>Category</th>
                                    <th>Job Types</th>
                                    <th>Applications</th>
                                    <th>Created &amp; Expired</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdManageAdPage;