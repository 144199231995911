import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

// Define initial state
const initialState = {
  deals: [],
  loading: false,
  error: null,
};

const dealSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setDealStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setDealSuccess: (state, action) => {
      state.loading = false;
      state.deals = action.payload;
    },
    setDealFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setDealStart, setDealSuccess, setDealFailure } =
  dealSlice.actions;

export default dealSlice.reducer;

export const fetchDeals = () => async (dispatch) => {
  try {
    dispatch(setDealStart());
    const docRef = doc(firestore, "Public", "Deals");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const dealsData = docSnap.data();

      var Data = [];
      if (dealsData) {
        Object.entries(dealsData).forEach(([key, value]) => {
          Data.push(
            Object.assign({
              Id: key,
              Author: value.Author,
              Date: value.Date,
              ImgUrl: value.ImgUrl,
              Title: value.Title,
              link: value.Link,
              Description: value.Description,
            })
          );
        });
      }

      dispatch(setDealSuccess(Data));
    } else {
      dispatch(setDealFailure("Document not found"));
    }
  } catch (error) {
    dispatch(setDealFailure(error.message));
  }
};
