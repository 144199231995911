import SectionJobsSidebar1 from "../../sections/jobs/sidebar/section-jobs-sidebar1";
import SectionJobsList from "../../sections/jobs/section-jobs-list";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import { useEffect, useState } from "react";
import { loadScript } from "../../../../../globals/constants";
import { useParams } from "react-router-dom";

function JobsListPage() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const _filterConfig = {
    prefix: "Showing",
    type: "jobs",
    total: data?.length,
    showRange: false,
    showingUpto: "",
  };

  useEffect(() => {
    loadScript("js/custom.js");
  });

  const { selectedDesignation, selectedSpecialization, selectedLocation } =
    useParams();

  const Datas = (item) => {
    setData(item);
  };

  const loadings = (data) => {
    setLoading(data);
  };

  return (
    <>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionJobsSidebar1
                selectedDesignation={selectedDesignation}
                selectedSpecialization={selectedSpecialization}
                selectedLocation={selectedLocation}
                Datas={Datas}
                loadings={loadings}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              {/*Filter Short By*/}

              <SectionJobsList data={data} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobsListPage;
