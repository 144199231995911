import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { empRoute, employer } from "../../../../globals/route-names";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function empInventory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const empInventory = useSelector((state) => state.EmpDashboard.empInventory);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Inventory</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Inventory</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.Dashboard_Header_Link} target="_blank">
              <img
                src={empAds?.Dashboard_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="panel-body wt-panel-body bg-white">
        <div className="twm-dashboard-candidates-wrap">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="twm-dash-candidates-list">
                <div className="twm-media">
                  <div className="twm-media-pic">
                    <img
                      src="https://c2m-edutech.com/demo/person1.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="twm-mid-content">
                  <a href="#" className="twm-job-title">
                    <h4>Database Inventory</h4>
                  </a>

                  <div className="twm-fot-content">
                    <div className="twm-left-info">
                      <div className="twm-jobs-vacancies">
                        {/* {empInventory?.Database_Total} */}
                        <span> {empInventory?.Database_Remaining}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className="twm-dash-candidates-list">
                <div className="twm-media">
                  <div className="twm-media-pic">
                    <img
                      src="https://c2m-edutech.com/demo/person4.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="twm-mid-content">
                  <a href="#" className="twm-job-title">
                    <h4>Post Job Inventory</h4>
                  </a>

                  <div className="twm-fot-content">
                    <div className="twm-left-info">
                      <div className="twm-left-info">
                        <div className="twm-jobs-vacancies">
                          {/* {empInventory?.JobPost_Total} */}
                          <span>{empInventory?.JobPost_Remaining} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="text-center">
              <button
                onClick={() => {
                  navigate(empRoute(employer.PRICING));
                }}
                className="site-button m-r5"
              >
                Purchase More
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
        <NavLink to={empAds?.Dashboard_Footer_Link} target="_blank">
          <img
            src={empAds?.Dashboard_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>
    </>
  );
}

export default empInventory;
