import JobZImage from "../../../common/jobz-img";
import { NavLink, useLocation } from "react-router-dom";
import { loadScript, setMenuActive } from "../../../../globals/constants";
import { adprovider, adRoute, publicUser } from "../../../../globals/route-names";

import { useEffect } from "react";

function AdSidebarSection(props) {
    const currentpath = useLocation().pathname;

    useEffect(() => {
        loadScript("js/custom.js");
        loadScript("js/ad-sidebar.js");
    })

    return (
        <>
            <nav id="sidebar-admin-wraper" className={props.sidebarActive ? "" : "active"}>
                <div className="page-logo">
                    <NavLink to={publicUser.HOME1}><JobZImage id="skin_page_logo" src="images/logo-dark.png" alt="" /></NavLink>
                </div>
                <div className="admin-nav scrollbar-macosx">
                    <ul>
                        <li
                            className={setMenuActive(currentpath, adRoute(adprovider.DASHBOARD))}>
                            <NavLink to={adRoute(adprovider.DASHBOARD)}><i className="fa fa-home" /><span className="admin-nav-text">Dashboard</span></NavLink>
                        </li>
                        <li
                            className={setMenuActive(currentpath, adRoute(adprovider.DEAL))}>
                            <NavLink to={adRoute(adprovider.DEAL)}><i className="fa fa-user-tie" /><span className="admin-nav-text">Deal Of the Day</span></NavLink>
                        </li>
                        <li><hr/></li>
                        <li
                            className={setMenuActive(currentpath, adRoute(adprovider.POST_A_AD))}>
                            <NavLink to={adRoute(adprovider.POST_A_AD)}><i className="fa fa-suitcase" /><span className="admin-nav-text">Post a New Ad</span></NavLink>
                        </li>
                        <li
                            className={setMenuActive(currentpath, adRoute(adprovider.MANAGE_AD))}>
                            <NavLink to={adRoute(adprovider.MANAGE_AD)}><i className="fa fa-share-square" /><span className="admin-nav-text">Manage Ads</span></NavLink>
                        </li>
                        <li><hr/></li>
                        <li className={setMenuActive(currentpath, adRoute(adprovider.PAYMENTS))}>
                            <NavLink to={adRoute(adprovider.PAYMENTS)}><i className="fa fa-money-bill-alt" /><span className="admin-nav-text">Payment History</span></NavLink>
                        </li>
                        <li className={setMenuActive(currentpath, adRoute(adprovider.FAQ))}>
                            <NavLink to={adRoute(adprovider.FAQ)}><i className="fa fa-file-download" /><span className="admin-nav-text">FAQ</span></NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default AdSidebarSection;