function ContactUsPage() {
  return (
    <>
      <div className="section-full twm-contact-one">
        <div className="section-content">
          <div className="container">
            {/* CONTACT FORM*/}
            <div className="contact-one-inner">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="contact-form-outer">
                    {/* title="" START*/}
                    <div className="section-head left wt-small-separator-outer">
                      <h2 className="wt-title">Send Us a Message</h2>
                      <p>
                        Feel free to contact us and we will get back to you as
                        soon as we can.
                      </p>
                    </div>
                    {/* title="" END*/}
                    <form className="cons-contact-form" method="post">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-3">
                            <input
                              name="username"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-3">
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-3">
                            <input
                              name="phone"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Phone"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-3">
                            <input
                              name="subject"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <textarea
                              name="message"
                              className="form-control"
                              rows={3}
                              placeholder="Message"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button type="submit" className="site-button">
                            Submit Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="contact-info-wrap">
                    <div className="contact-info">
                      <div className="contact-info-section">
                        <div className="c-info-column">
                          <div className="c-info-icon">
                            <i className=" fas fa-map-marker-alt" />
                          </div>
                          <h3 className="twm-title">In the bay area?</h3>
                          <p>
                            LACNAR SERVICES LLP 1715, E AND F BLOCK , DATTAGALLI
                            , MYSORE KUVEMPUNAGAR MYSORE-570023 KARNATAKA
                          </p>
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon custome-size">
                            <i className="fas fa-mobile-alt" />
                          </div>
                          <h3 className="twm-title">Feel free to contact us</h3>
                          <p>
                            <a href="tel:+216-761-8331">+91 9110424920</a>
                          </p>
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon">
                            <i className="fas fa-envelope" />
                          </div>
                          <h3 className="twm-title">Support</h3>
                          <p>info@lacnar.com</p>
                          <p>contact@lacnar.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", height: "500px" }}>
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d302.3450000159032!2d76.62328904817508!3d12.285983674271199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1723652641297!5m2!1sen!2sin"
          allowFullScreen={true}
          aria-hidden="false"
          tabIndex="0"
          title="Location Map"
        />
      </div>
    </>
  );
}

export default ContactUsPage;
