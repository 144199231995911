import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../../candidate/common/no-data/Index";
import { loadScript } from "../../../../../globals/constants";
import { fetchDashboard } from "../../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpManageJob } from "../../../../../reducers/employer/EmpManageJobSlice";
import { fetchGeneralempAds } from "../../../../../reducers/public-user/empAdsSlice";
import JobViewPopup from "../../../../common/popups/popup-job-view";

import { IconButton, Stack, Tooltip } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

function EmpManageJobPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userRole = useSelector((state) => state.AuthStatus.userRole);
  const manageJobsList = useSelector((state) => state.EmpManageJob);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpManageJob(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  const handleViewClick = (job) => {
    setSelectedJob(job); // Set the selected job data
  };

  return (
    <>
      {manageJobsList?.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Manage Jobs</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>My Job Listing</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.ManageJobs_Header_Link} target="_blank">
                  <img
                    src={empAds?.ManageJobs_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          {manageJobsList?.EmpManageJob?.length > 0 ? (
            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">
                  <i className="fa fa-suitcase" /> Job Details
                </h4>
              </div>
              <div className="panel-body wt-panel-body p-a20 m-b30 ">
                <div className="twm-D_table p-a20 table-responsive">
                  <table
                    id="jobs_bookmark_table"
                    className="table table-bordered twm-bookmark-list-wrap"
                  >
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>companyName</th>
                        <th>Job Title</th>
                        <th>Designation</th>
                        <th>Experience</th>
                        <th>posts</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {manageJobsList?.EmpManageJob?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.Company}</td>
                          <td>{item.JobTitle}</td>
                          <td>{item.Designation}</td>
                          <td>{item.Experience}</td>
                          <td>{item.Posts}</td>

                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="twm-table-controls">
                              <ul className="twm-DT-controls-icon list-unstyled">
                                <li>
                                  <a
                                    data-bs-toggle="modal"
                                    href="#saved-jobs-view"
                                    role="button"
                                    class="custom-toltip"
                                  >
                                    <Tooltip title={"View"}>
                                      <IconButton
                                        onClick={() => handleViewClick(item)}
                                      >
                                        <RemoveRedEyeIcon
                                          style={{
                                            fontSize: "15px",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Sl.No</th>
                        <th>companyName</th>
                        <th>Job Title</th>
                        <th>Designation</th>
                        <th>Experience</th>
                        <th>posts</th>

                        <th>Action</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.ManageJobs_Footer_Link} target="_blank">
              <img
                src={empAds?.ManageJobs_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
          <JobViewPopup selectedJob={selectedJob} show={true} />
        </>
      )}
    </>
  );
}
export default EmpManageJobPage;
