import { Route, Routes } from "react-router-dom";
import { candidate } from "../globals/route-names";

import CanDashboardPage from "../app/pannels/candidate/components/can-dashboard";
import CanDealPage from "../app/pannels/candidate/components/can-deal";
import CanProfilePage from "../app/pannels/candidate/components/can-profile";
import CanAppliedJobs from "../app/pannels/candidate/components/can-applied-jobs";
import CanMessages1Page from "../app/pannels/candidate/messages/can-messages1";
import CanNotificationPage from "../app/pannels/candidate/components/can-notifications";
import CanMyResumePage from "../app/pannels/candidate/components/can-resume";

import CanFaqPage from "../app/pannels/candidate/components/can-faq";
import CanSearchJobsPage from "../app/pannels/candidate/components/can-search-jobs";
import CanSavedJobsPage from "../app/pannels/candidate/components/can-saved-jobs";
import CanCVManagerPage from "../app/pannels/candidate/components/can-cv-manager";
import CanJobAlertsPage from "../app/pannels/candidate/components/can-job-alerts";
import CanUpdatePasswordPage from "../app/pannels/candidate/components/can-update-password";
import CanChatPage from "../app/pannels/candidate/components/can-chat";
import Error404Page from "../app/pannels/public-user/components/pages/error404";

function CandidateRoutes() {
  return (
    <Routes>
      <Route path={candidate.DASHBOARD} element={<CanDashboardPage />} />
      <Route path={candidate.DEAL} element={<CanDealPage />} />
      <Route path={candidate.PROFILE} element={<CanProfilePage />} />
      <Route path={candidate.APPLIED_JOBS} element={<CanAppliedJobs />} />
      <Route path={candidate.RESUME} element={<CanMyResumePage />} />
      <Route path={candidate.MESSAGES1} element={<CanMessages1Page />} />
      <Route path={candidate.NOTIFICATIONS} element={<CanNotificationPage />} />
      <Route path={candidate.FAQ} element={<CanFaqPage />} />
      <Route path={candidate.SEARCH_JOBS} element={<CanSearchJobsPage />} />
      <Route path={candidate.SAVED_JOBS} element={<CanSavedJobsPage />} />
      <Route path={candidate.CV_MANAGER} element={<CanCVManagerPage />} />
      <Route path={candidate.ALERTS} element={<CanJobAlertsPage />} />
      <Route
        path={candidate.UPDATE_PASSWORD}
        element={<CanUpdatePasswordPage />}
      />
      <Route path={candidate.CHAT} element={<CanChatPage />} />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

export default CandidateRoutes;
