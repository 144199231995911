import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { OnlineStatusProvider } from "./OnlineStatusProvider";
import OfflineNotification from "./OfflineNotification";

import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

import AuthStatusSliceReducer from "./reducers/auth/AuthStatusSlice";

import blogSliceReducer from "./reducers/public-user/blogSlice";
import privacyPolicySliceReducer from "./reducers/public-user/privacyPolicySlice";
import refundPolicySliceReducer from "./reducers/public-user/refundPolicySlice";
import termsConditionsSliceReducer from "./reducers/public-user/termsConditionsSlice";
import blogGridSliceReducer from "./reducers/public-user/blogGridSlice";
import featuredJobsSliceReducer from "./reducers/public-user/featureJobsSlice";
import adsSliceReducer from "./reducers/public-user/adsSlice";
import empAdsSliceReducer from "./reducers/public-user/empAdsSlice";

import EmpDashboardSliceReducer from "./reducers/employer/EmpDashboardSlice";
import EmpDealsSliceReducer from "./reducers/employer/EmpDealsSlice";
import EmpPostJobSliceReducer from "./reducers/employer/EmpPostJobSlice";
import EmpManageJobSliceReducer from "./reducers/employer/EmpManageJobSlice";
import EmpCandidateSearchSliceReducer from "./reducers/employer/EmpCandidateSearchSlice";
import EmpBookmarkSliceReducer from "./reducers/employer/EmpBookmarkSlice";
import EmpResumeAlertSliceReducer from "./reducers/employer/EmpResumeAlertSlice";
import EmpPaymentHistorySliceReducer from "./reducers/employer/EmpPaymentHistorySlice";
import EmpFaqSliceReducer from "./reducers/employer/EmpFaqSlice";
import EmpMessageSliceReducer from "./reducers/employer/EmpMessageSlice";
import EmpNotificationSliceReducer from "./reducers/employer/EmpNotificationSlice";
import EmpProfileSliceReducer from "./reducers/employer/EmpProfileSlice";
import EmpPasswordSliceReducer from "./reducers/employer/EmpPasswordSlice";
import EmpTicketsSliceReducer from "./reducers/employer/EmpTicketsSlice";

import CanDashboardSliceReducer from "./reducers/candidate/CanDashboardSlice";
import CanDealsSliceReducer from "./reducers/candidate/CanDealsSlice";
import CanSearchJobSliceReducer from "./reducers/candidate/CanSearchJobSlice";
import CanSavedJobsSliceReducer from "./reducers/candidate/CanSavedJobsSlice";
import CanJobAlertSliceReducer from "./reducers/candidate/CanJobAlertSlice";
import CanAppliedJobsSliceReducer from "./reducers/candidate/CanAppliedJobsSlice";
import CanResumeManagerSliceReducer from "./reducers/candidate/CanResumeManagerSlice";
import CanFaqSliceReducer from "./reducers/candidate/CanFaqSlice";
import CanMessageSliceReducer from "./reducers/candidate/CanMessageSlice";
import CanNotificationSliceReducer from "./reducers/candidate/CanNotificationSlice";
import CanProfileSliceReducer from "./reducers/candidate/CanProfileSlice";
import CanPasswordSliceReducer from "./reducers/candidate/CanPasswordSlice";
import CanAddsSliceReducer from "./reducers/candidate/CanAddsSlice";

import AdDashboardSliceReducer from "./reducers/adprovider/AdDashboardSlice";
import AdFaqSliceReducer from "./reducers/adprovider/AdFaqSlice";
import AdDealsSliceReducer from "./reducers/adprovider/AdDealsSlice";
import AdPaymentHistorySliceReducer from "./reducers/adprovider/AdPaymentHistorySlice";
import AdManageAdSliceReducer from "./reducers/adprovider/AdManageAdSlice";
import AdPostAdSliceReducer from "./reducers/adprovider/AdPostAdSlice";
import AdMessageSliceReducer from "./reducers/adprovider/AdMessageSlice";
import AdNotificationSliceReducer from "./reducers/adprovider/AdNotificationSlice";
import AdProfileSliceReducer from "./reducers/adprovider/AdProfileSlice";
import AdPasswordSliceReducer from "./reducers/adprovider/AdPasswordSlice";

const store = configureStore({
  reducer: {
    //Auth Reducers
    AuthStatus: AuthStatusSliceReducer,

    //Public or General Reducers
    blogList: blogSliceReducer,
    PrivacyPolicy: privacyPolicySliceReducer,
    RefundPolicy: refundPolicySliceReducer,
    TermsConditions: termsConditionsSliceReducer,
    blogGrid: blogGridSliceReducer,
    featuredJobs: featuredJobsSliceReducer,
    ads: adsSliceReducer,
    empAds: empAdsSliceReducer,

    //Employer Reducers
    EmpDashboard: EmpDashboardSliceReducer,
    EmpDeals: EmpDealsSliceReducer,
    EmpPostJob: EmpPostJobSliceReducer,
    EmpManageJob: EmpManageJobSliceReducer,
    EmpCandidateSearch: EmpCandidateSearchSliceReducer,
    EmpBookmark: EmpBookmarkSliceReducer,
    EmpResumeAlert: EmpResumeAlertSliceReducer,
    EmpPaymentHistory: EmpPaymentHistorySliceReducer,
    EmpFaq: EmpFaqSliceReducer,
    EmpMessage: EmpMessageSliceReducer,
    EmpNotification: EmpNotificationSliceReducer,
    EmpProfile: EmpProfileSliceReducer,
    EmpPassword: EmpPasswordSliceReducer,
    EmpTickets: EmpTicketsSliceReducer,

    //Candidiate Reducers
    CanDashboard: CanDashboardSliceReducer,
    CanDeals: CanDealsSliceReducer,
    CanSearchJob: CanSearchJobSliceReducer,
    CanSavedJobs: CanSavedJobsSliceReducer,
    CanJobAlert: CanJobAlertSliceReducer,
    CanAppliedJobs: CanAppliedJobsSliceReducer,
    CanResumeManager: CanResumeManagerSliceReducer,
    CanFaq: CanFaqSliceReducer,
    CanMessage: CanMessageSliceReducer,
    CanNotification: CanNotificationSliceReducer,
    CanProfile: CanProfileSliceReducer,
    CanPassword: CanPasswordSliceReducer,
    CanAdds: CanAddsSliceReducer,

    //Ad Client Reducers
    AdDashboard: AdDashboardSliceReducer,
    AdFaq: AdFaqSliceReducer,
    AdDeals: AdDealsSliceReducer,
    AdPaymentHistory: AdPaymentHistorySliceReducer,
    AdManageAd: AdManageAdSliceReducer,
    AdPostAd: AdPostAdSliceReducer,
    AdMessage: AdMessageSliceReducer,
    AdNotification: AdNotificationSliceReducer,
    AdProfile: AdProfileSliceReducer,
    AdPassword: AdPasswordSliceReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <OnlineStatusProvider>
    <BrowserRouter basename="/">
      <Provider store={store}>
        <App />
        <OfflineNotification />
      </Provider>
    </BrowserRouter>
  </OnlineStatusProvider>
);
