import { createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

// Initial Value of Slice
const initialState = {
  CanJobAlert: [],
  loading: false,
  error: null,
};

// Slice
const CanJobAlertSlice = createSlice({
  name: "CanJobAlert",
  initialState,
  reducers: {
    setJobAlertStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setJobAlertSuccess: (state, action) => {
      state.loading = false;
      state.CanJobAlert = action.payload;
    },
    setJobAlertFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Export of Slice
export const { setJobAlertStart, setJobAlertSuccess, setJobAlertFailure } =
  CanJobAlertSlice.actions;
export default CanJobAlertSlice.reducer;

// Thunk
export const fetchJobAlerts = (lookingFor) => async (dispatch) => {
  const { Designation, Location, Specialization } = lookingFor;

  try {
    dispatch(setJobAlertStart());

    // Base query
    let jobAlertsQuery = query(collection(firestore, "Jobs"));

    // Add where clauses only if values are defined
    if (Designation) {
      jobAlertsQuery = query(
        jobAlertsQuery,
        where("Designation", "==", Designation)
      );
    }
    if (Location) {
      jobAlertsQuery = query(jobAlertsQuery, where("District", "==", Location));
    }
    if (Specialization) {
      jobAlertsQuery = query(
        jobAlertsQuery,
        where("Specialization", "==", Specialization)
      );
    }

    // Add ordering and limit
    // jobAlertsQuery = query(jobAlertsQuery, orderBy("Date", "desc"), limit(10));
    jobAlertsQuery = query(jobAlertsQuery, orderBy("Date", "desc"), limit(10));

    // Execute the query
    const querySnapshot = await getDocs(jobAlertsQuery);

    // Process the results
    const jobAlerts = querySnapshot.docs.map((doc) => ({
      Id: doc.id,
      ...doc.data(),
    }));

    dispatch(setJobAlertSuccess(jobAlerts));
  } catch (error) {
    dispatch(setJobAlertFailure(error.message));
  }
};
