import { useEffect, useState } from "react";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Index from "../common/no-data/Index";
import CircularProgress from "../../../common/loading/Index";
import { loadScript } from "../../../../globals/constants";
import { publicUser } from "../../../../globals/route-names";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchAppliedJobs } from "../../../../reducers/candidate/CanAppliedJobsSlice";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";
import JobViewPopup from "../../../common/popups/popup-job-view";
import {
  Box,
  Typography,
  Button,
  Container,
  Tooltip,
  IconButton,
  Grid,
  Select,
  MenuItem,
  Snackbar,
  ThemeProvider,
  createTheme,
  Pagination,
  Stack,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { candidate, canRoute } from "../../../../globals/route-names";

function CanAppliedJobsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const appliedJobsList = useSelector((state) => state.CanAppliedJobs);
  const ads = useSelector((state) => state.ads.ads);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const CanDashboard = useSelector((state) => state.CanDashboard.canDashboard);

  const lookingFor = useSelector(
    (state) => state.CanDashboard.canProfile.LookingFor
  );
  const [selectedJob, setSelectedJob] = useState(null);

  const updatedItems = appliedJobsList?.CanAppliedJobs?.map((item) => {
    const milliseconds = item.AppliedDate?.seconds * 1000;
    const totalMilliseconds =
      milliseconds + item.AppliedDate?.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY HH:MM A");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAppliedJobs(userUUID, lookingFor));
  }, [dispatch, userUUID]);

  useEffect(() => {
    if (updatedItems && updatedItems.length > 0) {
      $(document).ready(function () {
        $("#jobs_applied_table").DataTable();
      });
    }
  }, [updatedItems]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralAds());
  }, [dispatch]);

  const handleViewClick = (job) => {
    setSelectedJob(job); // Set the selected job data
  };

  return (
    <>
      {appliedJobsList && appliedJobsList.loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Applied Jobs</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Applied Jobs</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={ads?.AppliedJobs_Header_Link} target="_blank">
                  <img
                    src={ads?.AppliedJobs_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {CanDashboard?.TopBar_1 !== "Profile : Verified" ? (
            <Container maxWidth="md">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                  textAlign: "center",
                  bgcolor: "background.default",
                  p: 3,
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <UpdateIcon
                  style={{ color: "#39CE6D" }}
                  sx={{ fontSize: 60, mb: 2 }}
                />
                <Typography variant="h4" gutterBottom>
                  Complete Your Profile
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                  To enhance your job search experience, please update your
                  profile with the latest Resume.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ backgroundColor: "#39CE6D" }}
                  onClick={() => {
                    navigate(canRoute(candidate.PROFILE));
                  }}
                  sx={{ textTransform: "none", px: 4 }}
                >
                  Update Profile
                </Button>
              </Box>
            </Container>
          ) : (
            <>
              {updatedItems && updatedItems.length > 0 ? (
                <>
                  <div className="twm-right-section-panel candidate-save-job site-bg-gray">
                    <div className="twm-D_table table-responsive">
                      <table
                        id="jobs_applied_table"
                        className="table table-bordered twm-candidate-save-job-list-wrap"
                      >
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>Job Title</th>
                            <th>Company</th>
                            <th>Applied Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {updatedItems?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="twm-candidate-save-job-list">
                                    <div className="twm-media">
                                      <div className="twm-media-pic">
                                        <img src={item.Logo} alt="#" />
                                      </div>
                                    </div>
                                    <div className="twm-mid-content">
                                      <h4>{item.JobTitle}</h4>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a href="#">{item.Company}</a>
                                </td>
                                <td>
                                  <div>{item.formattedDate}</div>
                                </td>
                                <td>
                                  <div className="col-6">
                                    <div className="twm-table-controls">
                                      <ul className="twm-DT-controls-icon list-unstyled d-flex justify-content-end">
                                        <li>
                                          {item.Status === false ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  "Job is No longer Available"
                                                }
                                              >
                                                <span>
                                                  <IconButton
                                                    disabled
                                                    style={{
                                                      color: "grey",
                                                    }}
                                                  >
                                                    <RemoveRedEyeIcon
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    />
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <>
                                              <a
                                                data-bs-toggle="modal"
                                                href="#saved-jobs-view"
                                                role="button"
                                                className="custom-toltip"
                                              >
                                                <Tooltip title={"View Job"}>
                                                  <span>
                                                    <IconButton
                                                      onClick={() =>
                                                        handleViewClick(item)
                                                      }
                                                    >
                                                      <RemoveRedEyeIcon
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      />
                                                    </IconButton>
                                                  </span>
                                                </Tooltip>
                                              </a>
                                            </>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Sl No</th>
                            <th>Job Title</th>
                            <th>Company</th>
                            <th>Applied Date</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>

                  <JobViewPopup selectedJob={selectedJob} show={true} />
                </>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Index />
                </div>
              )}
            </>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={ads?.AppliedJobs_Footer_Link} target="_blank">
              <img
                src={ads?.AppliedJobs_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}

export default CanAppliedJobsPage;
