import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id : "Primary-1",
            Title : "Orthoptist",
            Address : "Apollo Hospitals",
            Category : "ophthalmologist",
            JobType : "Fulltime",
            Applicants : "16 Applied",
            CreatedDate : "08/06/2023",
            ExpiredDate : "18/06/2023",
            Action : "",
            ImgUrl : "https://c2m-edutech.com/demo/photo6_124.jpg"
        },
        {
            Id : "Primary-2",
            Title : "Mental Health Services",
            Address : "Manipal Hospitals", 
            Category : "Psychiatrists",
            JobType : "Fulltime",
            Applicants : "06 Applied",
            CreatedDate : "28/05/2023",
            ExpiredDate : "08/06/2023",
            Action : "",
            ImgUrl : "https://c2m-edutech.com/demo/photo5_124.jpg"
        }
    ]
}

//Action of Slice
const AdManageAdSlice = createSlice({
    name: "AdManageAd",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = AdManageAdSlice.actions;
export default AdManageAdSlice.reducer;