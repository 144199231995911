import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
  EmpResumeAlert: [
    // {
    //   Id: "Primary-1",
    //   NumberOfJobs: "3",
    //   Title: "Orthoptist",
    //   Description: "Megha",
    //   Time: "Hourly",
    // },
    // {
    //   Id: "Primary-2",
    //   NumberOfJobs: "5",
    //   Title: "Audiologist",
    //   Description: "Swathi S",
    //   Time: "Weekly",
    // },
  ],
  loading: false,
  error: null,
};

//Action of Slice
const EmpResumeAlertSlice = createSlice({
  name: "EmpResumeAlert",
  initialState,
  reducers: {
    setEmpResumeAlertStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpResumeAlertSuccess: (state, action) => {
      state.loading = false;
      state.EmpResumeAlert = action.payload;
    },
    setEmpResumeAlertFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpResumeAlertStart,
  setEmpResumeAlertSuccess,
  setEmpResumeAlertFailure,
} = EmpResumeAlertSlice.actions;
export default EmpResumeAlertSlice.reducer;

export const fetchEmpResumeAlert = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpResumeAlertStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "EmpResumeAlert")
    );
    const EmpResumeAlert = [];
    querySnapshot.forEach((doc) => {
      EmpResumeAlert.push(doc.data());
    });
    dispatch(setEmpResumeAlertSuccess(EmpResumeAlert));
  } catch (error) {
    dispatch(setEmpResumeAlertFailure(error.message));
  }
};
