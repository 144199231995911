import { NavLink } from "react-router-dom";

function JobViewPopup(props) {
  const { selectedJob, show } = props;

  const renderKeyValue = (key, value) => {
    if (value) {
      return (
        <div className="modal-item">
          <strong>{key} </strong>
          <p>{value}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className="modal fade twm-saved-jobs-view"
        id="saved-jobs-view"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel-3"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h2 className="modal-title" id="sign_up_popupLabel-3">
                  Candidate Details
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="modal-body-content">
                  {selectedJob?.Name && (
                    <div
                      className="modal-item full-width description"
                      style={{ display: "flex" }}
                    >
                      <strong>Name </strong> &nbsp; : &nbsp;
                      <p>{selectedJob.Name}</p>
                    </div>
                  )}

                  {renderKeyValue("Gender", selectedJob?.Gender)}
                  {renderKeyValue("Designation", selectedJob?.Designation)}
                  {renderKeyValue(
                    "Specialization",
                    selectedJob?.Specialization
                  )}
                  {renderKeyValue("Experience", selectedJob?.Experience)}
                  {renderKeyValue("UnderGraduate", selectedJob?.UnderGraduate)}
                  {renderKeyValue("Post Graduate", selectedJob?.PostGraduate)}
                  {renderKeyValue("PhD", selectedJob?.PhD)}
                  {renderKeyValue("Others", selectedJob?.Others)}

                  {selectedJob?.District && selectedJob?.State && (
                    <div className="modal-item">
                      <strong>Location </strong>
                      <p>
                        {selectedJob.District} , {selectedJob.State}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="site-button pinte-clr"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <style>
        {`
          .modal-body-content {
            display: grid;
            grid-template-columns: 1fr 1fr; /* Two columns layout */
            gap: 5px; /* Adjust spacing between items */
          }
          .modal-item.full-width {
            grid-column: span 2; /* Span across both columns */
          }
          .description p {
            // white-space: nowrap; /* Prevent text wrapping */
            // overflow: hidden;
            // text-overflow: ellipsis; /* Add ellipsis if text overflows */
          }
          .logo-image {
            width: 100px; /* Adjust logo size */
            height: auto;
          }
        `}
      </style>
    </>
  );
}

export default JobViewPopup;
