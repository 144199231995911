import { useLocation } from "react-router-dom";
import PublicUserRoutes from "../routing/public-user-routes";
import InnerPageBanner from "../app/common/inner-page-banner";
import { showBanner, setBanner } from "../globals/banner-data";
import {
  showHeader,
  showFooter,
  setFooterType,
} from "../globals/layout-config";
import Header1 from "../app/common/header/header1";
import { getHeaderConfig } from "../globals/layout-config";

function PublicUserLayout() {
  const currentpath = useLocation().pathname;
  return (
    <>
      <div className="page-wraper">
        {/* Header */}
        {showHeader(currentpath) && (
          <Header1 _config={getHeaderConfig(currentpath)} />
        )}

        {/* Page Content */}
        <div className="page-content">
          {showBanner(currentpath) && (
            <InnerPageBanner
              _data={
                currentpath.split("/")[1] === "blog-detail"
                  ? {
                      title: "Blog detail",
                      crumb: "Blog detail",
                      imgUrl: "images/lacnar-img/blogs-bg.png",
                    }
                  : currentpath.split("/")[1] === "job-list"
                  ? {
                      title: "The Most Exciting Jobs",
                      crumb: "Search Jobs",
                      imgUrl: "images/lacnar-img/search-jobs-bg.png",
                    }
                  : setBanner(currentpath)
              }
            />
          )}
          <PublicUserRoutes />
        </div>

        {/* Footer */}
        {showFooter(currentpath) && setFooterType(currentpath)}

        {/* BUTTON TOP START */}
        <button className="scroltop">
          <span className="fa fa-angle-up  relative" id="btn-vibrate" />
        </button>
      </div>
    </>
  );
}

export default PublicUserLayout;
