import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpMessage: [
    {
      Id: "Primary-1",
      Timing: "4 hours ago",
      Name: "Priya B",
      Description: "All created by our Global",
      ImgUrl: "https://c2m-edutech.com/demo/person4.jpg",
    },
    {
      Id: "Primary-1",
      Timing: "2 hours ago",
      Name: "Vinay S",
      Description: "All created by our Global",
      ImgUrl: "https://c2m-edutech.com/demo/person1.jpg",
    },
    {
      Id: "Primary-1",
      Timing: "5 hours ago",
      Name: "Sooraj R",
      Description: "All created by our Global",
      ImgUrl: "https://c2m-edutech.com/demo/person2.jpg",
    },
    {
      Id: "Primary-1",
      Timing: "Friday",
      Name: "Sanjay Gowda ",
      Description: "All created by our Global",
      ImgUrl: "https://c2m-edutech.com/demo/person3.jpg",
    },
  ],
  loading: false,
  error: null,
};

//Action of Slice
const EmpMessageSlice = createSlice({
  name: "EmpMessage",
  initialState,
  reducers: {
    setEmpMessageStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpMessageSuccess: (state, action) => {
      state.loading = false;
      state.EmpMessage = action.payload;
    },
    setEmpMessageFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpMessageStart,
  setEmpMessageSuccess,
  setEmpMessageFailure,
} = EmpMessageSlice.actions;
export default EmpMessageSlice.reducer;

export const fetchEmpMessage = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpMessageStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "EmpMessage")
    );
    const EmpMessage = [];
    querySnapshot.forEach((doc) => {
      EmpMessage.push(doc.data());
    });
    dispatch(setEmpMessageSuccess(EmpMessage));
  } catch (error) {
    dispatch(setEmpMessageFailure(error.message));
  }
};
