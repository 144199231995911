import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../../../../globals/constants";
import { publicUser } from "../../../../../../globals/route-names";
import Contact from "../../../../../../images/contact.jpeg";

function SectionSideAdvert() {
  return (
    <>
      <div
        className="twm-advertisment"
        style={{
          backgroundImage: `url(${Contact})`,
          backgroundSize: "cover", // Adjusts how the image fits within the element
          backgroundPosition: "center", // Center the image
          backgroundRepeat: "no-repeat", // Prevents repeating the image
        }}
      >
        <div className="overlay" />
        <h4 className="twm-title">Recruiting?</h4>
        <p>
          Get Best Matched Jobs On your <br />
          Email. Add Resume NOW!
        </p>
        <NavLink to={publicUser.pages.ABOUT} className="site-button white">
          Read More
        </NavLink>
      </div>
    </>
  );
}

export default SectionSideAdvert;
