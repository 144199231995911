import React, { useState, useEffect } from "react";
import AppRoutes from "./routing/app-routes";
import Loader from "./app/common/loader";
import ScrollToTop from "./globals/scroll-to-top";
import { auth } from "./config/fbConfig";
import { useDispatch } from "react-redux";
import { login, logout, userData } from "./reducers/auth/AuthStatusSlice";
import OfflineNotification from "./OfflineNotification";

import { getIdTokenResult } from "firebase/auth";

function App() {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker.register("/service-worker.js").then(
        (registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        },
        (error) => {
          console.error("Service Worker registration failed:", error);
        }
      );
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      dispatch(userData(user));
      if (user) {
        dispatch(login(user?.uid));
      } else {
        dispatch(logout());
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
    <React.StrictMode>
      {isLoading && <Loader />}
      <ScrollToTop />
      <OfflineNotification />
      <AppRoutes />
    </React.StrictMode>
  );
}

export default App;
