import React, { useEffect, useState, useMemo } from "react";
import {
  ThemeProvider,
  createTheme,
  Pagination,
  Stack,
  CircularProgress,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Index from "../../candidate/common/no-data/Index";
import { loadScript } from "../../../../globals/constants";
import { publicUser } from "../../../../globals/route-names";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpDeals } from "../../../../reducers/employer/EmpDealsSlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

function EmpDealsPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const EmpDealsList = useSelector((state) => state.EmpDeals.EmpDeals);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);

  const itemsPerPage = 6;
  const totalPages = Math.ceil(EmpDealsList?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, EmpDealsList?.length);
  const currentItems = EmpDealsList.slice(startIndex, endIndex);

  const dealsData = currentItems?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    return {
      ...item,
      date: date,
      formattedDate: moment(date).format("MMM Do YYYY"), // Optional formatting
    };
  });
  dealsData.sort((a, b) => b.date - a.date);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpDeals(userUUID));
  }, [dispatch]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch]);

  return (
    <div>
      {EmpDealsList.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Offers</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Offers</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Deal_Header_Link} target="_blank">
                  <img
                    src={empAds?.Deal_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {currentItems.length > 0 ? (
            <div className="section-full p-t50 p-b90 site-bg-white">
              <div className="container">
                <div className="masonry-wrap row d-flex">
                  {dealsData.map((item) => (
                    <div
                      className="masonry-item col-lg-4 col-md-12"
                      key={item.id}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        className="blog-post twm-blog-post-1-outer"
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="wt-post-media"
                          style={{ flex: "0 1 auto" }}
                        >
                          <NavLink to={item.Link} target="_blank">
                            <img
                              src={item.ImgUrl}
                              alt="Deal Img"
                              style={{
                                height: "200px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </NavLink>
                        </div>
                        <div
                          className="wt-post-info"
                          style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            className="wt-post-meta"
                            style={{ flex: "0 1 auto" }}
                          >
                            <ul>
                              <li className="post-date">
                                {item.formattedDate}
                              </li>
                              <li className="post-author">
                                By{" "}
                                <NavLink to={item.Link} target="_blank">
                                  <p>{item.Author}</p>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                          <div
                            className="wt-post-title"
                            style={{ flex: "0 1 auto" }}
                          >
                            <h4 className="post-title">
                              <NavLink to={publicUser.blog.DETAIL}>
                                <p
                                  style={{
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 2,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    lineHeight: "1.5",
                                    maxWidth: "16rem",
                                    textAlign: "justify",
                                  }}
                                >
                                  {item.Title}
                                </p>
                              </NavLink>
                            </h4>
                          </div>
                          <div className="wt-post-text" style={{ flex: 1 }}>
                            <p
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                lineHeight: "1.5",
                                maxWidth: "16rem",
                                textAlign: "justify",
                              }}
                            >
                              {item.Description}
                            </p>
                          </div>
                          <div
                            className="wt-post-readmore"
                            style={{ flex: "0 1 auto" }}
                          >
                            <NavLink
                              to={item.OfferUrl}
                              className="site-button-link site-text-primary"
                              target="_blank"
                            >
                              Know More
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <ThemeProvider theme={themes}>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                      shape="rounded"
                    />
                  </Stack>
                </ThemeProvider>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={empAds?.Deal_Footer_Link} target="_blank">
              <img
                src={empAds?.Deal_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </div>
  );
}
export default EmpDealsPage;

const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});
