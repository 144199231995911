function TermsConditions() {
  return (
    <>
      <div className="p-t120 p-b90 site-bg-white Policy">
        <div className="container">
          {/* POLICY SECTION START */}
          <h4>PURPOSE</h4>
          <ul>
            <p>
              lacnar.com is intended only to serve as a preliminary medium of
              contact and exchange of information for its users / members /
              visitors who have a bona fide intention to contact and/or be
              contacted for the purposes related to genuine existing job
              vacancies and for other career enhancement services.
            </p>
          </ul>

          <h4>USE TO BE IN CONFORMITY WITH THE PURPOSE</h4>
          <ul>
            <p>
              lacnar.com is meant for the Purpose and only the exclusive use of
              the subscriber/registered user. Copying or downloading or
              recreating or sharing passwords or sub licensing or sharing in any
              manner which is not in accordance with these terms, is a misuse of
              the platform or service or product and Lacnar reserves its rights
              to act in such manner as to protect its loss of revenue or
              reputation or claim damages including stopping your service or
              access and reporting to relevant authorities. In the event you are
              found to be copying or misusing or transmitting or crawling any
              data or photographs or graphics or any information available on
              lacnar.com for any purpose other than that being a bonafide
              Purpose, we reserve the right to take such action that we deem fit
              including stopping access and claiming damages
            </p>
            <p>
              The site is a public site with free access and Lacnar assumes no
              liability for the quality and genuineness of responses. Lacnar
              cannot monitor the responses that a person may receive in response
              to information he/she has displayed on the site. The
              individual/company would have to conduct its own background checks
              on the bonafide nature of all response(s).
            </p>
            <p>
              You give us permission to use the information about actions that
              you have taken on lacnar.com in connection with ads, offers and
              other content (whether sponsored or not) that we display across
              our services, without any compensation to you. We use data and
              information about you to make relevant suggestions and
              recommendations to you and others.
            </p>
            <p>
              The platform may contain links to third party websites, these
              links are provided solely as convenience to You and the presence
              of these links should not under any circumstances be considered as
              an endorsement of the contents of the same, if You chose to access
              these websites you do so at your own risk.
            </p>
            <p>
              Whilst using this platform an obligation is cast upon you to only
              provide true and correct information and in the case of creating a
              profile you undertake to at all times keep the information up to
              date. Lacnar will not be liable on account of any inaccuracy of
              information on this web site. It is the responsibility of the
              visitor to further research the information on the site. Any
              breach of privacy or of the information provided by the consumer
              to Lacnar to be placed on the website by technical or any other
              means is not the responsibility of Lacnar Lacnar does not
              guarantee confidentiality of information provided to it by any
              person acquiring/using all/any information displayed on the
              lacnar.com website or any of its other websites / domains owned
              and operated by Lacnar.
            </p>
            <p>
              Lacnar does not share personally identifiable data of any
              individual with other companies / entities without obtaining
              permission except with those acting as our agents.. Lacnar shall
              share all such information that it has in its possession in
              response to legal process, such as a court order or subpoena. The
              user shall not utilize the services offered by lacnar.com/Lacnar
              in any manner so as to impair the interests and functioning of
              lacnar.com/Lacnar. The user undertakes not to duplicate, download,
              publish, modify and distribute material on lacnar.com unless
              specifically authorized by Lacnar in this regard.
            </p>
            <p>
              The user undertakes to use lacnar.com for his/her own purposes.
              Using content from lacnar.com for derivative works with a
              commercial motive without prior written consent from Lacnar is
              strictly prohibited.
            </p>
            <p>
              Users undertake that the services offered by lacnar.com/ Lacnar
              shall not be utilized to upload, post, email, transmit or
              otherwise make available either directly or indirectly, any
              unsolicited bulk e-mail or unsolicited commercial e-mail. Lacnar
              reserves the right to filter and monitor and block the emails sent
              by you/user using the servers maintained by Lacnar to relay
              emails. All attempts shall be made by Lacnar and the user to abide
              by International Best Practices in containing and eliminating
              Spam.
            </p>
            <p>
              Users shall not spam the platform maintained by lacnar.com /
              Lacnar or indiscriminately and repeatedly post jobs/forward mail
              indiscriminately etc. Any conduct of the user in violation of this
              clause shall entitle Lacnar to forthwith terminate all services to
              the user without notice and to forfeit any amounts paid by him.
            </p>
            <p>
              The user shall not upload, post, transmit, publish, or distribute
              any material or information that is unlawful, or which may
              potentially be perceived as being harmful, threatening, abusive,
              harassing, defamatory, libelous, vulgar, obscene, or racially,
              ethnically, or otherwise objectionable.
            </p>
            <p>
              The user expressly states that the resume/insertion or
              information/ data being fed into the network of Lacnar by the user
              is correct and complete in all respects and does not contain any
              false, distorted, manipulated, fraudulent or misleading facts or
              averments. Lacnar expressly disclaims any liability arising out of
              the said resume insertion/information/ data so fed into the
              network of Lacnar by the user. Further, the user agrees to
              indemnify Lacnar for all losses incurred by Lacnar due to any
              false, distorted, manipulated, defamatory, libelous, vulgar,
              obscene, fraudulent or misleading facts or otherwise objectionable
              averments made by the user on the network of Lacnar.
            </p>
            <p>
              The User is solely responsible for maintaining confidentiality of
              the User password and user identification and all activities and
              transmission performed by the User through his user identification
              and shall be solely responsible for carrying out any online or
              off-line transaction involving credit cards / debit cards or such
              other forms of instruments or documents for making such
              transactions and Lacnar assumes no responsibility or liability for
              their improper use of information relating to such usage of credit
              cards / debit cards used by the subscriber online / off-line.
            </p>
            <p>
              The User/Subscriber/Visitor to lacnar.com does hereby specifically
              agree that he/she shall, at all times, comply with the
              requirements of the Information Technology Act, 2000 as also
              rules, regulations, guidelines, by laws and notifications made
              thereunder, while assessing or feeding any resume/ insertion or
              information/data into the computers, computer systems or computer
              network of Lacnar.{" "}
            </p>
            <p>
              The said User/ subscriber/ visitor to lacnar.com and/or its
              affiliated websites does further unequivocally declare that in
              case he violates any provisions of the Information Technology Act
              2000 and/or rules, regulations, guidelines, by laws and
              notifications made thereunder, he shall alone be responsible for
              all his acts, deeds and things and that he alone shall be liable
              for civil and criminal liability there under or under any other
              law for the time being in force.
            </p>
            <p>
              The User is solely responsible for obtaining, at his own cost, all
              licenses, permits, consents, approvals and intellectual property
              or other rights as may be required by the user for using the
              Service.
            </p>
          </ul>
          <h4>
            THE USER REPRESENTS, WARRANTS AND COVENANTS THAT ITS USE OF
            LACNAR.COM SHALL NOT BE DONE IN A MANNER SO AS TO:
          </h4>
          <ul>
            <p>
              Access the Platform for purposes of extracting content to be used
              for training a machine learning or AI model, without the express
              prior written permission. Violate any applicable local,
              provincial, state, national or international law, statute,
              ordinance, rule or regulation.
            </p>
            <p>
              Interfere with or disrupt computer networks connected to
              lacnar.com: Impersonate any other person or entity, or make any
              misrepresentation as to your employment by or affiliation with any
              other person or entity: Forge headers or in any manner manipulate
              identifiers in order to disguise the origin of any user
              information:
            </p>
            <p>
              Interfere with or disrupt the use of lacnar.com by any other user,
              nor "stalk", threaten, or in any manner harass another user: Use
              lacnar.com in such a manner as to gain unauthorized entry or
              access to the computer systems of others: Reproduce, copy, modify,
              sell, store, distribute or otherwise exploit for any commercial
              purposes lacnar.com, or any component thereof (including, but not
              limited to any materials or information accessible through
              lacnar.com):
            </p>
            <p>
              Use content from the Site for derivative works with a commercial
              motive without prior written consent of the Lacnar. Use any
              device, software or routine to interfere or attempt to interfere
              with the proper working of lacnar.com: or Impose an unreasonable
              or disproportionately large load on lacnar.com infrastructure.
              Spam lacnar.com/Lacnar by indiscriminately and repeatedly posting
              content or forwarding mail that may be considered spam etc. Access
              data not intended for you or log into server or account that you
              are not authorized to access: Constitute an act of reverse
              engineering, decompiling, disassembling, deciphering or otherwise
              attempting to derive the source code for the Site or any related
              technology or any part thereof Engage in "framing," "mirroring,"
              or otherwise simulating the appearance or function of the Site
              Attempt to probe, scan or test the vulnerability of a system or
              network: Use automated means to crawl and/or scrape content from
              lacnar.com and to manually scrape content from lacnar.com:
            </p>
            <p>
              The Site uses technological means to exclude Robots etc. from
              crawling it and scraping content. You undertake not to circumvent
              these methods. Access the Site except through the interfaces
              expressly provided by Lacnar: Attempt or breach security or
              authentication measures without proper authorization: Providing
              deeplinks into lacnar.com without prior permission of Lacnar is
              prohibited.
            </p>
            <p>
              Extracting data from lacnar.com using any automated process such
              as spiders, crawlers etc. or through any manual process for a
              purpose which has not been authorised in writing. Upload, post,
              email, transmit or otherwise make available either directly or
              indirectly, any unsolicited bulk e-mail or unsolicited commercial
              e-mail.
            </p>
            <p>
              Lacnar will not be party to any legal proceedings between a user
              (e.g. a subscriber) and a party contracted through the site. In
              case Lacnar is implicated in any legal proceedings, costs will be
              recovered from the party that names Lacnar Lacnar however will
              abide by any court order served on it through due process. Lacnar
              controls and operates this Platform from its headquarters in Noida
              and makes no representation that the materials on lacnar.com are
              appropriate or available for use in other locations. If you use
              this Website from other locations, you are responsible for
              compliance with applicable local laws including but not limited to
              the export and import regulations of other countries.
            </p>
            <p>
              In case a person using the world wide web/internet receives a spam
              or virus which includes a link to lacnar.com or to any other site
              maintained, operated or owned by Lacnar, it should not be held
              responsible for the same. Lacnar assumes no responsibility for
              such mails. The services provided by the websites maintained,
              operated or owned by Lacnar do not extend to acting as an agent
              (express or implied) on behalf of any subscriber or user. Lacnar
              has no agents and does not operate through any agents save for
              those specifically mentioned on the home page of the website.
            </p>
            <p>
              If any dispute arises between a user/users and Lacnar arising out
              of use of lacnar.com or thereafter, in connection with the
              validity, interpretation, implementation or alleged breach of any
              provision of these Terms and Conditions, the dispute shall be
              referred to a sole arbitrator who shall be an independent and
              neutral third party identified by the Company. Decision of the
              arbitrator shall be final and binding on both the parties to the
              dispute. The place of arbitration shall be New Delhi, India. The
              Arbitration & Conciliation Act, 1996 as amended, shall govern the
              arbitration proceedings.
            </p>
          </ul>
        </div>
      </div>
    </>
  );
}

export default TermsConditions;
