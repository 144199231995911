import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id: "Primary-1",
            Timing: "4 hours ago",
            Name: "Priya B",
            Description: "All created by our Global",
            ImgUrl : "https://c2m-edutech.com/demo/person4.jpg"
        },
        {
            Id: "Primary-1",
            Timing: "2 hours ago",
            Name: "Vinay S",
            Description: "All created by our Global",
            ImgUrl : "https://c2m-edutech.com/demo/person1.jpg"
        },
        {
            Id: "Primary-1",
            Timing: "5 hours ago",
            Name: "Sooraj R",
            Description: "All created by our Global",
            ImgUrl : "https://c2m-edutech.com/demo/person2.jpg"
        },
        {
            Id: "Primary-1",
            Timing: "Friday",
            Name: "Sanjay Gowda ",
            Description: "All created by our Global",
            ImgUrl : "https://c2m-edutech.com/demo/person3.jpg"
        }
    ]
}

//Action of Slice
const AdMessageSlice = createSlice({
    name: "AdMessage",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = AdMessageSlice.actions;
export default AdMessageSlice.reducer;