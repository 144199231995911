import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice

const initialState = {
  List: {
    Id: "01",
    link: "https://www.medplusmart.com",
    imageUrl:
      "https://static2.medplusmart.com/live/bannerImage/Mart/2583118798827a98990b096141eca9cd.jpg",
  },
  loading: false,
  error: null,
};

//Action of Slice
const CanAddsSlice = createSlice({
  name: "CanAdds",
  initialState,
  reducers: {
    setAddsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setAddsSuccess: (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
    },
    setAddsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const { setAddsStart } = CanAddsSlice.actions;
export default CanAddsSlice.reducer;
