import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button, Snackbar, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import JobZImage from "../../../common/jobz-img";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { NavLink } from "react-router-dom";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  company_name: Yup.string().required("Company name is required"),
  company_phone: Yup.string()
    .matches(/^[0-9]*$/, "Phone number must be digits only")
    .required("Phone number is required"),
  company_Email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  company_website: Yup.string()
    .url("Invalid URL format")
    .required("Website is required"),
  company_since: Yup.string().required("Established since is required"),
  team_size: Yup.string().required("Team size is required"),
  description: Yup.string().required("Description is required"),
  facebook: Yup.string().url("Invalid URL format"),
  twitter: Yup.string().url("Invalid URL format"),
  linkedin: Yup.string().url("Invalid URL format"),
  whatsapp: Yup.string().matches(
    /^[0-9]*$/,
    "Whatsapp number must be digits only"
  ),
  instagram: Yup.string().url("Invalid URL format"),
  youtube: Yup.string().url("Invalid URL format"),
  address_1: Yup.string().required("Address Line 1 is required"),
  address_2: Yup.string(),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  pincode: Yup.string()
    .matches(/^[0-9]*$/, "Pincode must be digits only")
    .required("Pincode is required"),
  logo: Yup.mixed()
    .test("fileSize", "File too large", (value) => {
      return value && value.size <= 1048576; // 1MB
    })
    .test("fileType", "Unsupported File Format", (value) => {
      return (
        value && ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
      );
    }),
});

function EmpCompanyProfilePage() {
  const dispatch = useDispatch();
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const EmpProfile = useSelector((state) => state.EmpDashboard.empProfile);
  const empAds = useSelector((state) => state.empAds.empAds);

  // Initialize formData state
  const [formData, setFormData] = useState({
    company_name: "",
    company_phone: "",
    company_Email: "",
    company_website: "",
    company_since: "",
    team_size: "",
    description: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    whatsapp: "",
    instagram: "",
    youtube: "",
    address_1: "",
    address_2: "",
    state: "",
    district: "",
    pincode: "",
    logo: null,
  });

  // Load script and fetch dashboard data
  useEffect(() => {
    loadScript("js/custom.js");
    dispatch(fetchDashboard(userUUID));
  }, [dispatch, userUUID]);

  useEffect(() => {
    dispatch(fetchGeneralempAds());
  }, [dispatch]);

  // Update formData when EmpProfile changes
  useEffect(() => {
    if (EmpProfile) {
      setFormData({
        company_name: EmpProfile?.Basic?.FirstName || "",
        company_phone: EmpProfile?.Basic?.LastName || "",
        company_Email: EmpProfile?.Basic?.Description || "",
        company_website: EmpProfile?.Basic?.DOB || "",
        company_since: EmpProfile?.Basic?.DOB || "",
        team_size: "", // Set as per your requirement
        description: EmpProfile?.Basic?.Description || "",
        facebook: EmpProfile?.SocialNetwork?.Facebook || "",
        twitter: "", // Set as per your requirement
        linkedin: EmpProfile?.SocialNetwork?.Linkedin || "",
        whatsapp: EmpProfile?.SocialNetwork?.Whatsapp || "",
        instagram: EmpProfile?.SocialNetwork?.Instagram || "",
        youtube: "", // Set as per your requirement
        address_1: EmpProfile?.Address?.Address_1 || "",
        address_2: EmpProfile?.Address?.Address_2 || "",
        state: EmpProfile?.Address?.State || "",
        district: EmpProfile?.Address?.District || "",
        pincode: EmpProfile?.Address?.Pincode?.toString() || "",
        logo: null,
      });
    }
  }, [EmpProfile]);

  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });
  const [isEditMode, setIsEditMode] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    setSnackbar({ open: true, msg: "Form submitted successfully!" });
  };

  const handleClose = () => {
    setSnackbar({ open: false, msg: "" });
  };

  const states = ["State1", "State2", "State3"]; // Replace with actual states
  const districts = ["District1", "District2", "District3"]; // Replace with actual districts

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          {/* Company Profile Header */}

          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Company Profile!</h2>
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <a href="#">Dashboard</a>
                  <span>Company Profile!</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Profile_Header_Link} target="_blank">
                  <img
                    src={empAds?.Profile_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>

          {/* Logo and Cover Image */}

          {/* Basic Information */}
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-tittle m-a0">Basic Information</h4>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="edit-button-end">
                <Tooltip title={isEditMode ? "Close" : "Edit"}>
                  <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    {isEditMode ? (
                      <CloseIcon fontSize="small" />
                    ) : (
                      <EditIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading wt-panel-heading p-a20">
                  <h4 className="panel-tittle m-a0">Logo and Cover Image</h4>
                </div>
                <div className="panel-body wt-panel-body p-a20 p-b0 m-b30">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <div className="dashboard-profile-pic">
                          <div className="dashboard-profile-photo">
                            <JobZImage
                              src="images/jobs-company/pic1.jpg"
                              alt=""
                            />
                            <div className="upload-btn-wrapper">
                              <div id="upload-image-grid" />
                              <button
                                className="site-button button-sm"
                                disabled={!isEditMode}
                              >
                                Upload Photo
                              </button>
                              <input
                                type="file"
                                name="logo"
                                disabled={!isEditMode}
                                id="file-uploader"
                                accept=".jpg, .jpeg, .png"
                                onChange={(event) => {
                                  const file = event.currentTarget.files[0];
                                  if (file) {
                                    setFieldValue("logo", file);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <p>
                            <b>Company Logo :- </b> Max file size is 1MB,
                            Minimum dimension: 136 x 136 And Suitable files are
                            .jpg &amp; .png
                          </p>
                          <ErrorMessage
                            name="logo"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Company Details */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <Field
                      type="text"
                      name="company_name"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="company_name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Phone</label>
                    <Field
                      type="text"
                      name="company_phone"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="company_phone"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <Field
                      type="email"
                      name="company_Email"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="company_Email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Website</label>
                    <Field
                      type="text"
                      name="company_website"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="company_website"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Established Since</label>
                    <Field
                      type="date"
                      name="company_since"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="company_since"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Team Size</label>
                    <Field
                      type="text"
                      name="team_size"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="team_size"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <Field
                      as="textarea"
                      name="description"
                      rows="3"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Social Network */}
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-tittle m-a0">Social Network</h4>
            </div>
            <div className="panel-body wt-panel-body p-a20 m-b30">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Facebook</label>
                    <Field
                      type="text"
                      name="facebook"
                      className="form-control"
                      disabled={!isEditMode}
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                    />
                    <ErrorMessage
                      name="facebook"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Twitter</label>
                    <Field
                      type="text"
                      name="twitter"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="twitter"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>LinkedIn</label>
                    <Field
                      type="text"
                      name="linkedin"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="linkedin"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Whatsapp</label>
                    <Field
                      type="text"
                      name="whatsapp"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="whatsapp"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Instagram</label>
                    <Field
                      type="text"
                      name="instagram"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="instagram"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Youtube</label>
                    <Field
                      type="text"
                      name="youtube"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="youtube"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Address */}
          <div className="panel panel-default">
            <div className="panel-heading wt-panel-heading p-a20">
              <h4 className="panel-tittle m-a0">Address</h4>
            </div>
            <div className="panel-body wt-panel-body p-a20">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Address Line 1</label>
                    <Field
                      type="text"
                      name="address_1"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="address_1"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Address Line 2</label>
                    <Field
                      type="text"
                      name="address_2"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="address_2"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <Field
                      as="select"
                      name="state"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    >
                      <option value="">Select State</option>
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>District</label>
                    <Field
                      as="select"
                      name="district"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    >
                      <option value="">Select District</option>
                      {districts.map((district, index) => (
                        <option key={index} value={district}>
                          {district}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="district"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Pincode</label>
                    <Field
                      type="text"
                      name="pincode"
                      className="form-control"
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                      disabled={!isEditMode}
                    />
                    <ErrorMessage
                      name="pincode"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Landmark</label>
                    <Field
                      type="text"
                      name="landmark"
                      className="form-control"
                      disabled={!isEditMode}
                      style={{
                        backgroundColor:
                          isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                      }}
                    />
                    <ErrorMessage
                      name="landmark"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center">
            {isEditMode ? (
              <button type="submit" className="site-button m-b30">
                Save Changes
              </button>
            ) : (
              <button
                type="button"
                className="site-button m-b30"
                onClick={() => setIsEditMode(true)}
              >
                Edit Profile
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EmpCompanyProfilePage;
