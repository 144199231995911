import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  EmpBookmark: [],
  loading: false,
  error: null,
};

//Action of Slice
const EmpBookmarkSlice = createSlice({
  name: "EmpBookmark",
  initialState,
  reducers: {
    setEmpBookmarkStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setEmpBookmarkSuccess: (state, action) => {
      state.loading = false;
      state.EmpBookmark = action.payload;
    },
    setEmpBookmarkFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setEmpBookmarkStart,
  setEmpBookmarkSuccess,
  setEmpBookmarkFailure,
} = EmpBookmarkSlice.actions;
export default EmpBookmarkSlice.reducer;

export const fetchEmpBookmark = (userUUID) => async (dispatch) => {
  try {
    dispatch(setEmpBookmarkStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "BookmarkResume")
    );
    const EmpBookmark = [];
    querySnapshot.forEach((doc) => {
      EmpBookmark.push(doc.data());
    });
    dispatch(setEmpBookmarkSuccess(EmpBookmark));
  } catch (error) {
    dispatch(setEmpBookmarkFailure(error.message));
  }
};
