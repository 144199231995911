import JobZImage from "../../../../common/jobz-img";

function SectionCandidateRecentApplications() {
  return (
    <>
      <div className="panel panel-default">
        <div className="panel-heading wt-panel-heading p-a20">
          <h4 className="panel-tittle m-a0">Recent Applicants</h4>
        </div>
        <div className="panel-body wt-panel-body bg-white">
          <div className="twm-dashboard-candidates-wrap">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="twm-dash-candidates-list">
                  <div className="twm-media">
                    <div className="twm-media-pic">
                      <img
                        src="https://c2m-edutech.com/demo/person1.jpg"
                        alt="#"
                      />
                    </div>
                  </div>
                  <div className="twm-mid-content">
                    <a href="#" className="twm-job-title">
                      <h4>Praveen J </h4>
                    </a>
                    <p>Charted Accountant</p>
                    <div className="twm-fot-content">
                      <div className="twm-left-info">
                        <p className="twm-candidate-address">
                          <i className="feather-map-pin" />
                          Karnataka
                        </p>
                        <div className="twm-jobs-vacancies">
                          ₹2000<span>/ Day</span>
                        </div>
                      </div>
                      <div className="twm-right-btn">
                        <ul className="twm-controls-icon list-unstyled">
                          <li>
                            <button
                              title="View profile"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="fa fa-eye" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Send message"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-envelope-open" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Delete"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-trash-alt" />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="twm-dash-candidates-list">
                  <div className="twm-media">
                    <div className="twm-media-pic">
                      <img
                        src="https://c2m-edutech.com/demo/person4.jpg"
                        alt="#"
                      />
                    </div>
                  </div>
                  <div className="twm-mid-content">
                    <a href="#" className="twm-job-title">
                      <h4>Sahana B</h4>
                    </a>
                    <p>Medical Professed</p>
                    <div className="twm-fot-content">
                      <div className="twm-left-info">
                        <p className="twm-candidate-address">
                          <i className="feather-map-pin" />
                          New York
                        </p>
                        <div className="twm-jobs-vacancies">
                          ₹700<span>/ Hour</span>
                        </div>
                      </div>
                      <div className="twm-right-btn">
                        <ul className="twm-controls-icon list-unstyled">
                          <li>
                            <button
                              title="View profile"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="fa fa-eye" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Send message"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-envelope-open" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Delete"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-trash-alt" />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="twm-dash-candidates-list">
                  <div className="twm-media">
                    <div className="twm-media-pic">
                      <img
                        src="https://c2m-edutech.com/demo/person2.jpg"
                        alt="#"
                      />
                    </div>
                  </div>
                  <div className="twm-mid-content">
                    <a href="#" className="twm-job-title">
                      <h4>Harshith M</h4>
                    </a>
                    <p>Bank Manger</p>
                    <div className="twm-fot-content">
                      <div className="twm-left-info">
                        <p className="twm-candidate-address">
                          <i className="feather-map-pin" />
                          New York
                        </p>
                        <div className="twm-jobs-vacancies">
                          ₹1800<span>/ Day</span>
                        </div>
                      </div>
                      <div className="twm-right-btn">
                        <ul className="twm-controls-icon list-unstyled">
                          <li>
                            <button
                              title="View profile"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="fa fa-eye" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Send message"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-envelope-open" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Delete"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-trash-alt" />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="twm-dash-candidates-list">
                  <div className="twm-media">
                    <div className="twm-media-pic">
                      <img
                        src="https://c2m-edutech.com/demo/person3.jpg"
                        alt="#"
                      />
                    </div>
                  </div>
                  <div className="twm-mid-content">
                    <a href="#" className="twm-job-title">
                      <h4>Kiran </h4>
                    </a>
                    <p>IT Contractor</p>
                    <div className="twm-fot-content">
                      <div className="twm-left-info">
                        <p className="twm-candidate-address">
                          <i className="feather-map-pin" />
                          New York
                        </p>
                        <div className="twm-jobs-vacancies">
                          ₹9000<span>/ Week</span>
                        </div>
                      </div>
                      <div className="twm-right-btn">
                        <ul className="twm-controls-icon list-unstyled">
                          <li>
                            <button
                              title="View profile"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="fa fa-eye" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Send message"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-envelope-open" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Delete"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-trash-alt" />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="twm-dash-candidates-list">
                  <div className="twm-media">
                    <div className="twm-media-pic">
                      <img
                        src="https://c2m-edutech.com/demo/person4.jpg"
                        alt="#"
                      />
                    </div>
                  </div>
                  <div className="twm-mid-content">
                    <a href="#" className="twm-job-title">
                      <h4>Roshini S</h4>
                    </a>
                    <p>Charity &amp; Voluntary</p>
                    <div className="twm-fot-content">
                      <div className="twm-left-info">
                        <p className="twm-candidate-address">
                          <i className="feather-map-pin" />
                          New York
                        </p>
                        <div className="twm-jobs-vacancies">
                          ₹770<span>/ Hour</span>
                        </div>
                      </div>
                      <div className="twm-right-btn">
                        <ul className="twm-controls-icon list-unstyled">
                          <li>
                            <button
                              title="View profile"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="fa fa-eye" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Send message"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-envelope-open" />
                            </button>
                          </li>
                          <li>
                            <button
                              title="Delete"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="far fa-trash-alt" />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionCandidateRecentApplications;
