import React from "react";
import { publicUrlFor } from "../../../../../globals/constants";

function Index(props) {
  return (
    <div>
      {" "}
      <img
        src={publicUrlFor("images/nodata.png")}
        style={{ height: "323px" }}
      />
    </div>
  );
}

export default Index;
