import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import JobZImage from "../../../../common/jobz-img";
import Index from "../../../candidate/common/no-data/Index";
import { loadScript } from "../../../../../globals/constants";
import { fetchDashboard } from "../../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpMessage } from "../../../../../reducers/employer/EmpMessageSlice";

function EmpMessagesPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.EmpDashboard.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const EmpMessageList = useSelector((state) => state.EmpMessage);

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpMessage(userUUID));
  }, [dispatch]);

  return (
    <>
      {EmpMessageList?.loading === true ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          {" "}
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Messages</h2>
                <div className="breadcrumbs">
                  <a href="#">Home</a>
                  <a href="#">Dasboard</a>
                  <span>Messages</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={empAds?.Msg_Header_Link} target="_blank">
                  <img
                    src={empAds?.Msg_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          {EmpMessageList?.EmpMessage?.length > 0 ? (
            <div className="wt-admin-dashboard-msg-2">
              {/*Left Msg section*/}
              <div className="wt-dashboard-msg-user-list">
                <div className="user-msg-list-btn-outer">
                  <button className="user-msg-list-btn-close">Close</button>
                  <button className="user-msg-list-btn-open">
                    User Message
                  </button>
                </div>
                {/* Search Section Start*/}
                <div className="wt-dashboard-msg-search">
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Search Messages"
                      type="text"
                    />
                    <button className="btn" type="button">
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
                {/* Search Section End*/}
                {/* Search Section End*/}
                <div className="msg-find-list">
                  <select className="wt-select-box bs-select-hidden">
                    <option>Recent Chats</option>
                    <option>Short by Time</option>
                    <option>Short by Unread</option>
                  </select>
                </div>
                {/* Search Section End*/}
                {/* user msg list start*/}
                <div
                  id="msg-list-wrap"
                  className="wt-dashboard-msg-search-list scrollbar-macosx"
                >
                  {EmpMessageList?.EmpMessage?.map((item) => (
                    <div className="wt-dashboard-msg-search-list-wrap">
                      <a href="#" className="msg-user-info clearfix">
                        <div className="msg-user-timing">{item.Timing}</div>
                        <div className="msg-user-info-pic">
                          <img src={item.ImgUrl} alt="" />
                        </div>
                        <div className="msg-user-info-text">
                          <div className="msg-user-name">{item.Name}</div>
                          <div className="msg-user-discription">
                            {item.Description}
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
                {/* user msg list End*/}
              </div>
              {/*Right Msg section*/}
              <div className="wt-dashboard-msg-box">
                <div className="single-msg-user-name-box">
                  <div className="single-msg-short-discription">
                    <h4 className="single-msg-user-name">Priya B</h4>
                    Independent Web Designers and Developers.
                  </div>
                  <a href="#" className="message-action">
                    <i className="far fa-trash-alt" /> Delete Conversation
                  </a>
                </div>
                <div
                  id="msg-chat-wrap"
                  className="single-user-msg-conversation scrollbar-macosx"
                >
                  <div className="single-user-comment-wrap">
                    <div className="row">
                      <div className="col-xl-9 col-lg-12">
                        <div className="single-user-comment-block clearfix">
                          <div className="single-user-com-pic">
                            <JobZImage
                              src="images/user-avtar/pic4.jpg"
                              alt=""
                            />
                          </div>
                          <div className="single-user-com-text">
                            Breaking the endless cycle of meaningless text
                            message conversations starts with only talking to
                            someone who offers interesting topics opinions.
                          </div>
                          <div className="single-user-msg-time">12:13 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-user-comment-wrap sigle-user-reply">
                    <div className="row justify-content-end">
                      <div className="col-xl-9 col-lg-12">
                        <div className="single-user-comment-block clearfix">
                          <div className="single-user-com-pic">
                            <JobZImage
                              src="images/user-avtar/pic1.jpg"
                              alt=""
                            />
                          </div>
                          <div className="single-user-com-text">
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour.
                          </div>
                          <div className="single-user-msg-time">12:37 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-user-comment-wrap">
                    <div className="row">
                      <div className="col-xl-9 col-lg-12">
                        <div className="single-user-comment-block clearfix">
                          <div className="single-user-com-pic">
                            <JobZImage
                              src="images/user-avtar/pic4.jpg"
                              alt=""
                            />
                          </div>
                          <div className="single-user-com-text">
                            Breaking the endless cycle of meaningless text
                            message conversations starts with only talking to
                            someone who offers interesting topics opinions.
                          </div>
                          <div className="single-user-msg-time">12:13 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-user-comment-wrap sigle-user-reply">
                    <div className="row justify-content-end">
                      <div className="col-xl-9 col-lg-12">
                        <div className="single-user-comment-block clearfix">
                          <div className="single-user-com-pic">
                            <JobZImage
                              src="images/user-avtar/pic1.jpg"
                              alt=""
                            />
                          </div>
                          <div className="single-user-com-text">
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour.
                          </div>
                          <div className="single-user-msg-time">12:37 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-user-comment-wrap">
                    <div className="row">
                      <div className="col-xl-9 col-lg-12">
                        <div className="single-user-comment-block clearfix">
                          <div className="single-user-com-pic">
                            <JobZImage
                              src="images/user-avtar/pic4.jpg"
                              alt=""
                            />
                          </div>
                          <div className="single-user-com-text">
                            Breaking the endless cycle of meaningless text
                            message conversations starts with only talking to
                            someone who offers interesting topics opinions.
                          </div>
                          <div className="single-user-msg-time">12:13 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-user-comment-wrap sigle-user-reply">
                    <div className="row justify-content-end">
                      <div className="col-xl-9 col-lg-12">
                        <div className="single-user-comment-block clearfix">
                          <div className="single-user-com-pic">
                            <JobZImage
                              src="images/user-avtar/pic1.jpg"
                              alt=""
                            />
                          </div>
                          <div className="single-user-com-text">
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour.
                          </div>
                          <div className="single-user-msg-time">12:37 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-msg-reply-comment">
                  <div className="input-group">
                    <textarea
                      className="form-control"
                      placeholder="Type a message here"
                      defaultValue={""}
                    />
                    <button className="btn" type="button">
                      <i className="fa fa-paper-plane" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}
          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-4">
            <NavLink to={empAds?.Msg_Footer_Link} target="_blank">
              <img
                src={empAds?.Msg_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
    </>
  );
}
export default EmpMessagesPage;
