import React from "react";

export const designationOptions = [
  { label: "All Designation", value: "all-designation" },
  { label: "DES_1", value: "DES_1" },
  { label: "DES_2", value: "DES_2" },
  { label: "DES_3", value: "DES_3" },
];

export const specializationOptions = [
  { label: "All Specialization", value: "all-specialization" },
  { label: "SPE_1", value: "SPE_1" },
  { label: "SPE_2", value: "SPE_2" },
  { label: "SPE_3", value: "SPE_3" },
];

export const ProfessionalSector = [
  { label: "PRO_1", value: "PRO_1" },
  { label: "PRO_2", value: "PRO_2" },
  { label: "PRO_3", value: "PRO_3" },
];

export const locationOptions = [
  { label: "All Locations", value: "all-locations" },
  { label: "Bangalore", value: "bangalore" },
  { label: "Mysuru", value: "Mysuru" },
  { label: "Mangalore", value: "mangalore" },
  { label: "Hubli", value: "hubli" },
  { label: "Belgaum", value: "belgaum" },
  { label: "Gulbarga", value: "gulbarga" },
  { label: "Davangere", value: "davangere" },
  { label: "Bellary", value: "bellary" },
  { label: "Bijapur", value: "bijapur" },
  { label: "Shimoga", value: "shimoga" },
  { label: "Tumkur", value: "tumkur" },
  { label: "Raichur", value: "raichur" },
  { label: "Bidar", value: "bidar" },
  { label: "Hospet", value: "hospet" },
  { label: "Gadag", value: "gadag" },
  { label: "Kolar", value: "kolar" },
  { label: "Chitradurga", value: "chitradurga" },
  { label: "Mandya", value: "mandya" },
  { label: "Chikmagalur", value: "chikmagalur" },
  { label: "Hassan", value: "hassan" },
  { label: "Koppal", value: "koppal" },
  { label: "Udupi", value: "udupi" },
  { label: "Karwar", value: "karwar" },
  { label: "Dharwad", value: "dharwad" },
  { label: "Chikkaballapur", value: "chikkaballapur" },
  { label: "Ramanagara", value: "ramanagara" },
  { label: "Bagalkot", value: "bagalkot" },
  { label: "Yadgir", value: "yadgir" },
  { label: "Chamarajanagar", value: "chamarajanagar" },
  { label: "Kodagu", value: "kodagu" },
];

function StaticData() {
  return <div></div>;
}

export default StaticData;
