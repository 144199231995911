import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import SectionSideAdvert from "./section-side-advert";
import {
  designationOptions,
  specializationOptions,
  locationOptions,
} from "../../../../../common/static-data";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../../../../../config/fbConfig"; // Ensure you have Firebase configuration

function SectionJobsSidebar1({
  Datas,
  loadings,
  selectedDesignation,
  selectedSpecialization,
  selectedLocation,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  Datas(data);

  loadings(loading);

  const handleSearch = async (values) => {
    setLoading(true);
    window.scrollTo({ top: 0 });
    try {
      const filters = [];
      const { designation, specialization, location, fullTime, partTime } =
        values;

      if (designation !== "all-designation") {
        filters.push(where("Designation", "==", designation));
      }

      if (specialization !== "all-specialization") {
        filters.push(where("Specialization", "==", specialization));
      }

      if (location !== "all-locations") {
        filters.push(where("District", "==", location));
      }

      if (fullTime || partTime) {
        filters.push(
          where(
            "JobType",
            "in",
            [fullTime ? 0 : null, partTime ? 1 : null].filter((v) => v !== null)
          )
        );
      }

      const jobCollection = collection(firestore, "Jobs");
      const q =
        filters.length > 0 ? query(jobCollection, ...filters) : jobCollection;

      const querySnapshot = await getDocs(q);
      const jobs = querySnapshot.empty
        ? []
        : querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

      setData(jobs);
      setLoading(false);
    } catch (error) {
      console.error("Error getting documents: ", error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      designation: selectedDesignation || "all-designation",
      specialization: selectedSpecialization || "all-specialization",
      location: selectedLocation || "all-locations",
      fullTime: false,
      partTime: false,
    },
    validationSchema: Yup.object({
      designation: Yup.string().required("Designation is required"),
      specialization: Yup.string().required("Specialization is required"),
      location: Yup.string().required("Location is required"),
    }),
    onSubmit: (values) => {
      handleSearch(values);
    },
  });

  const handleReset = () => {
    window.scrollTo({ top: 0 });
    formik.resetForm({
      values: {
        designation: "all-designation",
        specialization: "all-specialization",
        location: "all-locations",
        fullTime: false,
        partTime: false,
      },
    });
    handleSearch({
      designation: "all-designation",
      specialization: "all-specialization",
      location: "all-locations",
      fullTime: false,
      partTime: false,
    });
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      designation: selectedDesignation || "all-designation",
      specialization: selectedSpecialization || "all-specialization",
      location: selectedLocation || "all-locations",
    });
  }, [selectedDesignation, selectedSpecialization, selectedLocation]);

  useEffect(() => {
    handleSearch(formik.values);
  }, []);

  return (
    <>
      <div className="side-bar">
        <div className="sidebar-elements search-bx">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
              <label
                className="section-head-small mb-4"
                htmlFor="designation-select"
              >
                Designation
              </label>
              <select
                id="designation-select"
                className="wt-select-bar-large selectpicker"
                data-live-search="true"
                name="designation"
                value={formik.values.designation}
                onChange={formik.handleChange}
              >
                {designationOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {formik.errors.designation && formik.touched.designation ? (
                <div className="error">{formik.errors.designation}</div>
              ) : null}
            </div>

            <div className="form-group mb-4">
              <label
                className="section-head-small mb-4"
                htmlFor="specialization-select"
              >
                Specialization
              </label>
              <select
                id="specialization-select"
                className="wt-select-bar-large selectpicker"
                data-live-search="true"
                name="specialization"
                value={formik.values.specialization}
                onChange={formik.handleChange}
              >
                {specializationOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              {formik.errors.specialization && formik.touched.specialization ? (
                <div className="error">{formik.errors.specialization}</div>
              ) : null}
            </div>

            {/* Location Dropdown */}
            <div className="form-group mb-4">
              <label
                className="section-head-small mb-4"
                htmlFor="location-select"
              >
                Location
              </label>
              <select
                id="location-select"
                className="wt-select-bar-large selectpicker"
                data-live-search="true"
                name="location"
                value={formik.values.location}
                onChange={formik.handleChange}
              >
                {locationOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {formik.errors.location && formik.touched.location ? (
                <div className="error">{formik.errors.location}</div>
              ) : null}
            </div>

            {/* Job Type Checkboxes */}
            <div className="twm-sidebar-ele-filter">
              <label className="section-head-small mb-4">Job Type</label>
              <ul>
                <li>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="fullTime"
                      name="fullTime"
                      checked={formik.values.fullTime}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="fullTime">
                      Full Time
                    </label>
                  </div>
                </li>

                <li>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="partTime"
                      name="partTime"
                      checked={formik.values.partTime}
                      onChange={formik.handleChange}
                    />
                    <label className="form-check-label" htmlFor="partTime">
                      Part Time
                    </label>
                  </div>
                </li>
              </ul>
              {formik.errors.jobType && (
                <div className="error" style={{ color: "red" }}>
                  {formik.errors.jobType}
                </div>
              )}
            </div>

            <button
              style={{ width: "100%", padding: "8px" }}
              type="submit"
              className="site-button"
            >
              Search
            </button>
            <button
              type="button"
              style={{
                width: "100%",
                padding: "8px",
                marginTop: "1rem",
                backgroundColor: "#046434",
              }}
              className="site-button"
              onClick={handleReset}
            >
              Reset
            </button>
          </form>
        </div>
      </div>
      <SectionSideAdvert />
    </>
  );
}

export default SectionJobsSidebar1;
