import { createSlice } from "@reduxjs/toolkit";
import { auth } from "../../config/fbConfig";
import { signOut } from "firebase/auth";

//Initial Value of Slice
const initialState = {
  loginStatus: false,
  userUUID: null,
  userDetails: {},
  userRole: null,
};

//Action of Slice
const authStatusSlice = createSlice({
  name: "AuthStatus",
  initialState,
  reducers: {
    login(state, userId) {
      state.loginStatus = true;
      state.userUUID = userId.payload;
    },
    logout(state) {
      state.loginStatus = false;
      state.userUUID = null;
      state.userDetails = {};
      state.userRole = null;
      signOut(auth)
        .then(() => {
          console.log("logout success");
        })
        .catch((error) => {
          alert("Error Try Again Later");
        });
    },
    userData(state, action) {
      state.userDetails = action.payload; // Store user details
    },
    userRole(state, action) {
      state.userRole = action.payload;
    },
  },
});

//Export of Slice
export const { login, logout, userData, userRole } = authStatusSlice.actions;
export default authStatusSlice.reducer;
