import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id : "Primary-1",
            TransactionID : "12345",
            Name : "Swathi",
            Amount : "1500",
            Date : "28/01/2023",
            Status : "Completed"
        }
    ]
}

//Action of Slice
const AdPaymentHistorySlice = createSlice({
    name: "AdPaymentHistory",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = AdPaymentHistorySlice.actions;
export default AdPaymentHistorySlice.reducer;