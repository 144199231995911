import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

const initialState = {
  ads: {},
  loading: true,
  error: null,
};

//Action of Slice
const adsSlice = createSlice({
  name: "ads",
  initialState,
  reducers: {
    setAdsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setAdsSuccess: (state, action) => {
      state.loading = false;
      state.ads = action.payload;
    },
    setAdsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const { setAdsStart, setAdsSuccess, setAdsFailure } = adsSlice.actions;
export default adsSlice.reducer;

export const fetchGeneralAds = () => async (dispatch) => {
  try {
    dispatch(setAdsStart());

    // Firestore document reference
    const docRef = doc(firestore, "Public", "EmployeeGeneral");

    // Attempt to get the document snapshot
    const docSnap = await getDoc(docRef);

    // Check if document exists
    if (docSnap.exists()) {
      const AdsData = docSnap.data();

      // Dispatch success with the retrieved data
      dispatch(setAdsSuccess(AdsData));
    } else {
      console.error("Document not found");
      dispatch(setAdsFailure("Document not found"));
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    dispatch(setAdsFailure(error.message));
  }
};
