import React, { Component } from 'react';
import SectionFaqGeneral from '../../public-user/sections/faq/section-faq-general';
import SectionFaqJobs from '../../public-user/sections/faq/section-faq-jobs';
import SectionFaqPayments from '../../public-user/sections/faq/section-faq-payment';
import SectionFaqReturn from '../../public-user/sections/faq/section-faq-return';

export default class AdFaqPage extends Component {
  render() {
    return (
      <div>
        {/*Headline Display Start*/}
        <div className="wt-admin-right-page-header clearfix">
          <h2>FAQ</h2>
          <div className="breadcrumbs"><a>Home</a><a>Dasboard</a><span>FAQ</span></div>
        </div>
        {/*Headline Display End*/}

        <div className="panel panel-default site-bg-white m-t30">
          {/*FAQ Section Display Start*/}
          <div className="panel-heading p-a20">
            <h4 className="panel-tittle m-a0">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#General" type="button" role="tab">General</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Jobs" type="button" role="tab" aria-controls="Jobs">Jobs</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Payment" type="button" role="tab" aria-controls="Payment">Payment</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#Return" type="button" role="tab" aria-controls="Return">Return</button>
                </li>
              </ul>
            </h4>
          </div>
          {/*FAQ Section Display End*/}

          {/*FAQ Display Start*/}
          <div className="twm-tabs-style-1 center p-t30">
            <div className="tab-content" id="myTabContent">
              {/*Tabs content one*/}
              <div className="tab-pane fade show active" id="General" role="tabpanel">
                <SectionFaqGeneral />
              </div>
              {/*Tabs content two*/}
              <div className="tab-pane fade" id="Jobs" role="tabpanel">
                <SectionFaqJobs />
              </div>
              {/*Tabs content three*/}
              <div className="tab-pane fade" id="Payment" role="tabpanel">
                <SectionFaqPayments />
              </div>
              {/*Tabs content Four*/}
              <div className="tab-pane fade" id="Return" role="tabpanel">
                <SectionFaqReturn />
              </div>
            </div>
          </div>
          {/*FAQ Display End*/}
        </div>
      </div>
    )
  }
}
