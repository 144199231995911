import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

const initialState = {
  CanSavedJobs: [],
  loading: true,
  error: null,
};

// Define the slice with initial state and reducers
const CanSavedJobsSlice = createSlice({
  name: "CanSavedJobs",
  initialState,
  reducers: {
    setSavedJobsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setSavedJobsSuccess: (state, action) => {
      state.loading = false;
      state.CanSavedJobs = action.payload;
    },
    setSavedJobsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Export actions
export const { setSavedJobsStart, setSavedJobsSuccess, setSavedJobsFailure } =
  CanSavedJobsSlice.actions;

// Export the reducer
export default CanSavedJobsSlice.reducer;

// Thunk to fetch saved jobs
export const fetchSavedJobs = (userUUID) => async (dispatch) => {
  try {
    dispatch(setSavedJobsStart());

    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "SavedJobs")
    );

    const savedJobs = querySnapshot.docs.map((doc) => ({
      Id: doc.id,
      ...doc.data(),
    }));

    dispatch(setSavedJobsSuccess(savedJobs));
  } catch (error) {
    dispatch(setSavedJobsFailure(error.message));
  }
};
