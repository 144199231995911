import { createSlice } from "@reduxjs/toolkit";

//Initial Value of Slice
const initialState = {
    List: [
        {
            Id : "Primary-1",
            OldPassword : "Old Password",
            NewPassword : "New Password",
            ConfirmPassword : "Confirm New Password"
        }
    ]
}

//Action of Slice
const CanPasswordSlice = createSlice({
    name: "CanPassword",
    initialState,
    reducers: {}
})

//Export of Slice
export const { } = CanPasswordSlice.actions;
export default CanPasswordSlice.reducer;