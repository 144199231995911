import { createSlice } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../config/fbConfig";

//Initial Value of Slice
const initialState = {
  CanMessage: [
    // {
    //   Id: "Primary-1",
    //   Timing: "4 hours ago",
    //   Name: "Priya B",
    //   Description: "All created by our Global",
    //   ImgUrl: "https://c2m-edutech.com/demo/person4.jpg",
    // },
    // {
    //   Id: "Primary-1",
    //   Timing: "2 hours ago",
    //   Name: "Vinay S",
    //   Description: "All created by our Global",
    //   ImgUrl: "https://c2m-edutech.com/demo/person1.jpg",
    // },
    // {
    //   Id: "Primary-1",
    //   Timing: "5 hours ago",
    //   Name: "Sooraj R",
    //   Description: "All created by our Global",
    //   ImgUrl: "https://c2m-edutech.com/demo/person2.jpg",
    // },
    // {
    //   Id: "Primary-1",
    //   Timing: "Friday",
    //   Name: "Sanjay Gowda",
    //   Description: "All created by our Global",
    //   ImgUrl: "https://c2m-edutech.com/demo/person3.jpg",
    // },
  ],
  loading: false,
  error: null,
};

//Action of Slice
const CanMessageSlice = createSlice({
  name: "CanMessage",
  initialState,
  reducers: {
    setCanMessageStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCanMessageSuccess: (state, action) => {
      state.loading = false;
      state.CanMessage = action.payload;
    },
    setCanMessageFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

//Export of Slice
export const {
  setCanMessageStart,
  setCanMessageSuccess,
  setCanMessageFailure,
} = CanMessageSlice.actions;

export default CanMessageSlice.reducer;

export const fetchCanMessage = (userUUID) => async (dispatch) => {
  try {
    dispatch(setCanMessageStart());
    const querySnapshot = await getDocs(
      collection(firestore, "Users", userUUID, "CanMessage")
    );
    const CanMessage = [];
    querySnapshot.forEach((doc) => {
      CanMessage.push(doc.data());
    });
    dispatch(setCanMessageSuccess(CanMessage));
  } catch (error) {
    dispatch(setCanMessageFailure(error.message));
  }
};
