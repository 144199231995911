import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Button,
  Box,
  Grid,
  Paper,
  Typography,
  Alert,
  Snackbar,
  IconButton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDropzone } from "react-dropzone";
import { storage, db } from "../../../../config/fbConfig";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CloseIcon from "@mui/icons-material/Close";
import { fetchDashboard } from "../../../../reducers/candidate/CanDashboardSlice";
import { fetchCanResumeManager } from "../../../../reducers/candidate/CanResumeManagerSlice";
import { fetchGeneralAds } from "../../../../reducers/public-user/adsSlice";

function CanMyResumePage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CanDashboard.loading);
  const ads = useSelector((state) => state.ads.ads);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const CanResumeManager = useSelector((state) => state.CanResumeManager);
  const canProfile = useSelector((state) => state.CanDashboard.canProfile);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  useEffect(() => {
    if (loading) {
      dispatch(fetchDashboard(userUUID));
      dispatch(fetchCanResumeManager(userUUID));
      dispatch(fetchGeneralAds());
    }
  }, [dispatch, loading, userUUID]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      const pdfFiles = acceptedFiles.filter(
        (file) => file.type === "application/pdf"
      );
      if (pdfFiles.length > 0) {
        setUploadedFile(pdfFiles[0]);
        setError(null);
      } else {
        setError("Only PDF files are allowed.");
      }
    },
    accept: "application/pdf",
    noClick: true,
    noKeyboard: true,
  });

  const handleUpload = async () => {
    setButtonLoading(true);
    if (uploadedFile) {
      try {
        const fileRef = ref(
          storage,
          `resumes/${uuidv4()}_${uploadedFile.name}`
        );
        const snapshot = await uploadBytes(fileRef, uploadedFile);
        const downloadURL = await getDownloadURL(snapshot.ref);

        await setDoc(
          doc(db, "Verify", userUUID),
          { Resume: downloadURL },
          { merge: true }
        );
        setSnackbarMsg("Document successfully written!");
        setSnackbarOpen(true);
        setUploadedFile(null);
      } catch (error) {
        console.error("Error during upload or Firestore operation: ", error);
      } finally {
        setButtonLoading(false);
      }
    } else {
      setButtonLoading(false);
    }
  };

  const handleCloseSnackbar = () => setSnackbarOpen(false);

  return (
    <>
      {CanResumeManager.loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Resume Manager</h2>
                <div className="breadcrumbs">
                  <a>Home</a>
                  <a>Dashboard</a>
                  <span>Resume Manager</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                <NavLink to={ads?.Resume_Header_Link} target="_blank">
                  <img
                    src={ads?.Resume_Header}
                    alt="Banner Image"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
            </div>
          </div>
          <Paper
            elevation={2}
            sx={{ padding: "3rem", backgroundColor: "#d4e6ff4f" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} align="center">
                <Box sx={{ padding: "2rem" }}>
                  <Paper
                    elevation={4}
                    sx={{
                      padding: "2rem",
                      backgroundColor: "#ffffffab",
                      borderRadius: "22px",
                    }}
                  >
                    <div style={{ marginBottom: "1rem" }}>
                      <VisibilityIcon
                        style={{
                          height: "39px",
                          width: "37px",
                          color: "#33cc66",
                        }}
                      />
                    </div>
                    <Typography
                      sx={{ marginBottom: "1rem", fontWeight: "bold" }}
                    >
                      View Resume
                    </Typography>
                    <Button
                      disabled={canProfile?.Resume === "NO_999"}
                      onClick={() => window.open(canProfile?.Resume, "_blank")}
                      variant="contained"
                    >
                      View Resume
                    </Button>
                  </Paper>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} align="center">
                <Box sx={{ padding: "2rem" }}>
                  <Paper
                    elevation={4}
                    sx={{
                      padding: "2rem",
                      backgroundColor: "#ffffffab",
                      borderRadius: "22px",
                    }}
                  >
                    <div style={{ marginBottom: "1rem" }}>
                      <CloudUploadIcon
                        style={{
                          height: "39px",
                          width: "37px",
                          color: "#33cc66",
                        }}
                      />
                    </div>
                    <Typography
                      sx={{ marginBottom: "1rem", fontWeight: "bold" }}
                    >
                      Upload Resume
                    </Typography>
                    <div
                      {...getRootProps()}
                      style={{ display: "inline-block", cursor: "pointer" }}
                    >
                      <input {...getInputProps()} style={{ display: "none" }} />
                      {buttonLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button onClick={open} variant="contained">
                          Select File
                        </Button>
                      )}
                    </div>
                    {error && (
                      <Alert severity="error" sx={{ marginTop: "1rem" }}>
                        {error}
                      </Alert>
                    )}
                    {uploadedFile && (
                      <>
                        <Typography
                          sx={{ marginTop: "1rem", fontStyle: "italic" }}
                        >
                          {uploadedFile.name}
                        </Typography>
                        {buttonLoading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            sx={{ marginTop: "1rem" }}
                            onClick={handleUpload}
                            variant="contained"
                            color="primary"
                          >
                            Upload
                          </Button>
                        )}
                      </>
                    )}
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            <NavLink to={ads?.Resume_Footer_Link} target="_blank">
              <img
                src={ads?.Resume_Footer}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMsg}
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              onClick={handleCloseSnackbar}
            >
              UNDO
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}

export default CanMyResumePage;
