import JobZImage from "../../../common/jobz-img";
import CountUp from "react-countup";
import SectionCandidateProfileViews from "../../candidate/sections/dashboard/section-can-profile-views";

function AdDashboardPage() {
  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <h2>Hello, Ad Provider</h2>
        <div className="breadcrumbs">
          <a>Home</a>
          <span>Dasboard</span>
        </div>
      </div>
      <div className="twm-dash-b-blocks mb-5">
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-1 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-address-book" />
                  </div>
                  <div className="wt-card-right wt-total-active-listing counter ">
                    <CountUp end={25} duration={10} />
                  </div>
                  <div className="wt-card-bottom ">
                    <h4 className="m-b0">Posted Jobs</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-2 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-file-alt" />
                  </div>
                  <div className="wt-card-right  wt-total-listing-view counter ">
                    <CountUp end={435} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Total Applications</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-3 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-envelope" />
                  </div>
                  <div className="wt-card-right wt-total-listing-review counter ">
                    <CountUp end={28} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Messages</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-12 mb-3">
            <div className="panel panel-default">
              <div className="panel-body wt-panel-body gradi-4 dashboard-card ">
                <div className="wt-card-wrap">
                  <div className="wt-card-icon">
                    <i className="far fa-bell" />
                  </div>
                  <div className="wt-card-right wt-total-listing-bookmarked counter ">
                    <CountUp end={18} duration={10} />
                  </div>
                  <div className="wt-card-bottom">
                    <h4 className="m-b0">Notifications</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="twm-pro-view-chart-wrap">
        <div className="row">
          <div className="col-xl-6 col-lg-12 col-md-12 mb-4">
            <SectionCandidateProfileViews />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 mb-4">
            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">Inbox</h4>
              </div>
              <div className="panel-body wt-panel-body bg-white">
                <div className="dashboard-messages-box-scroll scrollbar-macosx">
                  <div className="dashboard-messages-box">
                    <div className="dashboard-message-avtar">
                      <img
                        src="https://c2m-edutech.com/demo/person4.jpg"
                        alt=""
                      />
                    </div>
                    <div className="dashboard-message-area">
                      <h5>
                        Priya B<span>18 June 2023</span>
                      </h5>
                      <p>
                        Bring to the table win-win survival strategies to ensure
                        proactive domination. at the end of the day, going
                        forward, a new normal that has evolved from generation.
                      </p>
                    </div>
                  </div>
                  <div className="dashboard-messages-box">
                    <div className="dashboard-message-avtar">
                      <img
                        src="https://c2m-edutech.com/demo/person1.jpg"
                        alt=""
                      />
                    </div>
                    <div className="dashboard-message-area">
                      <h5>
                        Vinay S<span>19 June 2023</span>
                      </h5>
                      <p>
                        Bring to the table win-win survival strategies to ensure
                        proactive domination. at the end of the day, going
                        forward, a new normal that has evolved from generation.
                      </p>
                    </div>
                  </div>
                  <div className="dashboard-messages-box">
                    <div className="dashboard-message-avtar">
                      <img
                        src="https://c2m-edutech.com/demo/person2.jpg"
                        alt=""
                      />
                    </div>
                    <div className="dashboard-message-area">
                      <h5>
                        Sooraj R<span>20 June 2023</span>
                      </h5>
                      <p>
                        Bring to the table win-win survival strategies to ensure
                        proactive domination. at the end of the day, going
                        forward, a new normal that has evolved from generation.
                      </p>
                    </div>
                  </div>
                  <div className="dashboard-messages-box">
                    <div className="dashboard-message-avtar">
                      <img
                        src="https://c2m-edutech.com/demo/person3.jpg"
                        alt=""
                      />
                    </div>
                    <div className="dashboard-message-area">
                      <h5>
                        Sanjay Gowda<span>22 June 2023</span>
                      </h5>
                      <p>
                        Bring to the table win-win survival strategies to ensure
                        proactive domination. at the end of the day, going
                        forward, a new normal that has evolved from generation.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 mb-4">
            <div className="panel panel-default site-bg-white m-t30">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">
                  <i className="far fa-list-alt" />
                  Recent Activities
                </h4>
              </div>
              <div className="panel-body wt-panel-body">
                <div className="dashboard-list-box list-box-with-icon">
                  <ul>
                    <li>
                      <i className="fa fa-envelope text-success list-box-icon" />
                      Priya B has sent you{" "}
                      <a href="#" className="text-success">
                        private message!
                      </a>
                      <a
                        href="#"
                        className="close-list-item color-lebel clr-red"
                      >
                        <i className="far fa-trash-alt" />
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-suitcase text-primary list-box-icon" />
                      Your job for
                      <a href="#" className="text-primary">
                        HR Manager
                      </a>
                      has been approved!
                      <a
                        href="#"
                        className="close-list-item color-lebel clr-red"
                      >
                        <i className="far fa-trash-alt" />
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-bookmark text-warning list-box-icon" />
                      Someone bookmarked your
                      <a href="#" className="text-warning">
                        Dentist
                      </a>
                      Job listing!
                      <a
                        href="#"
                        className="close-list-item color-lebel clr-red"
                      >
                        <i className="far fa-trash-alt" />
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-tasks text-info list-box-icon" />
                      Your job listing Core
                      <a href="#" className="text-info">
                        Cardiologist
                      </a>{" "}
                      for Site Maintenance is expiring!
                      <a
                        href="#"
                        className="close-list-item color-lebel clr-red"
                      >
                        <i className="far fa-trash-alt" />
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-paperclip text-success list-box-icon" />
                      You have new application for
                      <a href="#" className="text-success">
                        {" "}
                        Receptionsit &amp; Designer!{" "}
                      </a>
                      <a
                        href="#"
                        className="close-list-item color-lebel clr-red"
                      >
                        <i className="far fa-trash-alt" />
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-suitcase text-danger list-box-icon" />
                      Your Magento Developer job expire
                      <a href="#" className="text-danger">
                        Renew
                      </a>{" "}
                      now it.
                      <a
                        href="#"
                        className="close-list-item color-lebel clr-red"
                      >
                        <i className="far fa-trash-alt" />
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-star site-text-orange list-box-icon" />
                      David left a
                      <a href="#" className="site-text-orange">
                        {" "}
                        review 4.5
                      </a>{" "}
                      for Real Estate Logo
                      <a
                        href="#"
                        className="close-list-item color-lebel clr-red"
                      >
                        <i className="far fa-trash-alt" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 mb-4">
            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-tittle m-a0">Recent Applicants</h4>
              </div>
              <div className="panel-body wt-panel-body bg-white">
                <div className="twm-dashboard-candidates-wrap">
                  <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Sanjay B</h4>
                          </a>
                          <p>Charted Accountant</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹2000<span>/ Day</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person4.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Sahana N</h4>
                          </a>
                          <p>Medical Professed</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹700<span>/ Hour</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person3.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Sooraj R</h4>
                          </a>
                          <p>Bank Manger</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹1800<span>/ Day</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Vinay S</h4>
                          </a>
                          <p>IT Contractor</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹9000<span>/ Week</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12">
                      <div className="twm-dash-candidates-list">
                        <div className="twm-media">
                          <div className="twm-media-pic">
                            <img
                              src="https://c2m-edutech.com/demo/person4.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <a href="#" className="twm-job-title">
                            <h4>Roshini S </h4>
                          </a>
                          <p>Charity &amp; Voluntary</p>
                          <div className="twm-fot-content">
                            <div className="twm-left-info">
                              <p className="twm-candidate-address">
                                <i className="feather-map-pin" />
                                New York
                              </p>
                              <div className="twm-jobs-vacancies">
                                ₹190<span>/ Hour</span>
                              </div>
                            </div>
                            <div className="twm-right-btn">
                              <ul className="twm-controls-icon list-unstyled">
                                <li>
                                  <button
                                    title="View profile"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="fa fa-eye" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Send message"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-envelope-open" />
                                  </button>
                                </li>
                                <li>
                                  <button
                                    title="Delete"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="far fa-trash-alt" />
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdDashboardPage;
